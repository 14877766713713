@import "../../../../../../../variables.sass"

.feature-card
    display: inline-block
    background: $card-background
    // border: 0.852405px solid #2F2F2F
    border-radius: 17.0481px
    // box-shadow: 0px 0px 8px rgba(84, 196, 252, 0.5)
    padding: 25px
    width: 95% !important
    margin: auto
    margin-left: 5px
    overflow: hidden
    min-height: 247px
    display: flex
    flex-direction: column
    justify-content: space-between
    .profile-text
        .profiles-name
            display: flex
            justify-content: space-between
            gap: 10px
            .user-image-name
                display: flex
                align-items: center
                flex-direction: column
                gap: 10px
                min-width: 40%
                .parter-image
                    width: 80px
                    height: 80px
                    min-width: 80px
                    min-height: 80px
                    object-fit: cover
                    border-radius: 50%
            .partner-area
                width: 100%
                text-align: center
                display: -webkit-box
                -webkit-line-clamp: 3
                -webkit-box-orient: vertical
                overflow: hidden
                text-overflow: ellipsis

    .profile-discription
        display: flex
        justify-content: space-around
        align-items: center
        width: 100%
        .deshboard-social
            // min-width: 40%
            // margin: auto
            .social-link-table
                display: flex
                align-items: center
                justify-content: center
                gap: 10px
                padding-left: 0
                margin-bottom: 0
                li
                    list-style: none
                    a
                        width: 24px
                        height: 24px
                        color: black
                        background: #4398FF
                        display: flex
                        align-items: center
                        justify-content: center
                        text-decoration: none
                        border-radius: 50%
        .view-discount-btn
            display: flex
            align-items: center
            gap: 8px
            padding: 6px 20px
            font-size: 14px
            img
                width: 28px
        h6
            background: linear-gradient(180deg, #2EAAF3 0%, #2EAAF3 100%)
            -webkit-background-clip: text
            -webkit-text-fill-color: transparent
            background-clip: text
            text-fill-color: transparent

@media screen and (max-width: 1600px)
    .feature-card
        .profile-text
            .profiles-name
                img
                    width: 65px
                    height: 65px
                    min-width: 65px
                    min-height: 65px

@media screen and (max-width: 1400px)
    .feature-card
        min-height: 230px
@media screen and (max-width: 1200px)
    .latest-bets-section
        .responsive-table
            padding-left: 0
@media screen and (max-width: 992px)
    .feature-card
        padding: 12px
        min-height: 165px
        .profile-text
            .profiles-name
                img
                    width: 55px
                    height: 55px
                    min-width: 55px
                    min-height: 55px
            .deshboard-social
                i
                    font-size: 15px
        .new-primary-button
            padding: 6px 15px
            font-size: 12px
            border-radius: 15.0792px
@media screen and (max-width: 767px)
    .feature-card
        min-height: 150px
        .profile-discription
            .view-discount-btn
                font-size: 12px !important
                padding: 1px 10px
                img
                    width: 22px !important
            .deshboard-social
                a
                    width: 17px
                    height: 17px
                    min-width: 17px
                    i
                        font-size: 12px
            .profiles-name
                img
                    width: 50px
                    height: 50px
                    min-width: 50px
                    min-height: 50px
