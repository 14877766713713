.accelerate-hero-section
    padding-top: 30px
    .accelerate-main-img
        max-height: 60px
    .launch-text
        display: flex
        align-items: center
        justify-content: center
        p
            font-family: 'Space Grotesk'
            font-style: normal
            font-weight: 700
            font-size: 13.4141px
            line-height: 80%
            letter-spacing: 0.39em
            text-transform: uppercase
            background: linear-gradient(90.03deg, #4398FF -5.59%, #2479DF 65.86%)
            -webkit-background-clip: text
            -webkit-text-fill-color: transparent
            background-clip: text
            text-fill-color: transparent
            margin-bottom: 0
            margin-right: 10px
        img
            margin-right: 10px
    .help-text
        p
            text-align: center
            color: #ffffff
        span
            font-weight: 700
            color: #ffffff
    .program-button
        display: flex
        justify-content: center
        margin-bottom: 100px
    .counter-card
        background: rgba(68, 68, 68, 0.25)
        border: 1px solid #2F2F2F
        border-radius: 35px
        text-align: center
        padding: 30px
        z-index: 1
        position: relative
        .counter-img
            height: 50px
            width: 50px
            object-fit: contain
            margin: auto
            margin-top: -55px
            margin-bottom: 15px
        h2
            font-family: 'Space Grotesk'
            font-style: normal
            background: linear-gradient(90deg, #4398FF 0%, #2479DF 58.11%)
            -webkit-background-clip: text
            -webkit-text-fill-color: transparent
            background-clip: text
            margin-bottom: 15px
            text-fill-color: transparent
        p
            margin-bottom: 0
    .blue-line
        background: linear-gradient(270deg, #4398FF 5.08%, rgba(255, 255, 255, 0) 112.4%)
        border-radius: 4.93956px
        height: 1.65px !important
        width: 150px
        display: flex
        top: 0%
        left: 0
        position: absolute
        -webkit-animation: linear infinite
        -webkit-animation-name: accelerateanimName
        -webkit-animation-duration: 10s
        z-index: -1
        &.blue-line-2
            top: 8%
            animation: accelerateanimName 5s linear infinite
        &.blue-line-3
            top: 16%
            animation: accelerateanimName 4s linear infinite
        &.blue-line-4
            top: 24%
            animation: accelerateanimName 3s linear infinite
        &.blue-line-5
            top: 32%
            animation: accelerateanimName 9s linear infinite
        &.blue-line-6
            top: 40%
            animation: accelerateanimName 7s linear infinite
        &.blue-line-7
            top: 48%
            animation: accelerateanimName 8s linear infinite
        &.blue-line-8
            top: 56%
            animation: accelerateanimName 3s linear infinite
        &.blue-line-9
            top: 64%
            animation: accelerateanimName 6s linear infinite
        &.blue-line-10
            top: 55%
            animation: accelerateanimName 4s linear infinite
    @keyframes accelerateanimName
        0%
            left: 0%
        100%
            left: 100%

.accelerate-program-section
    .projectDetails-top
        border-radius: 25.93px
        background: #000000D9
        border: 1px solid #2F2F2F
        padding: 30px
        .slick-slide
            .slide-content
                width: 95% !important
                margin: auto !important
                img, video
                    border-radius: 28.6875px
                    height: 300px
                    min-height: 300px
                    width: 100%
                    object-fit: cover
    .projectDetails
        padding: 0
        padding-bottom: 0
        .MuiBox-root, .css-1l0r181
            align-items: center

        .tab-data-alignment
            align-items: center
            display: flex
            .css-19kzrtu
                padding: 0 24px
                ul
                    margin-bottom: 0
                    li
                        color: #FFFFFF
                        line-height: 32px
        .paragraph-new
            line-height: 32px
        .css-10d9dml-MuiTabs-indicator, .css-7ozjwe
            background: transparent !important
        .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected, .css-1q2h7u5.Mui-selected
            background: #303030 !important
            border-radius: 15px 0px 0px 15px !important
            color: #4398FF !important
        .css-1h9z7r5-MuiButtonBase-root-MuiTab-root, .css-1q2h7u5
            margin: 5px 0 !important
            color: white !important
            text-align: left !important
            align-items: baseline !important
            text-transform: math-auto
            min-height: 35px !important
            font-size: 18px
        .css-35zqo5-MuiTabs-root, .css-1vr8b6v
            border-right: 2px solid !important
            border-color: #6B6B6B !important
            min-width: 330px !important
            height: 100% !important
        .css-lfwcke-MuiTabs-flexContainer, .css-j7qwjs
            margin-top: 30px !important
        .MuiTableCell-root
            border-bottom: none !important
            color: white !important
        .css-11xur9t-MuiPaper-root-MuiTableContainer-root, .css-13xy2my
            background: transparent !important
            box-shadow: none !important
.plunge-section
    .get-accelerated-btn
        background: rgba(24, 178, 255, 0.1)
        border-radius: 16px
        font-family: 'Space Grotesk'
        font-style: normal
        font-weight: 300
        font-size: 24.4px
        line-height: 31px
        text-align: center
        color: #ffffff
        border: 2px solid #18B2FF1A
        padding: 15px 40px
        display: flex
        margin: auto
        position: relative

.comprehensive-section
    z-index: 1
    h2
        margin-bottom: 0
        text-align: center
        background: linear-gradient(90deg, #4398FF 7.71%, #2479DF 63.43%)
        -webkit-background-clip: text
        -webkit-text-fill-color: transparent
        background-clip: text
        text-fill-color: transparent
    // h3
    //     font-family: 'Space Grotesk'
    //     font-style: normal
    //     font-weight: 700
    //     font-size: 47.5936px
    //     line-height: 104.5%
    //     color: #FFFFFF
    //     margin-bottom: 10px
    p
        width: 51%
    .text-mar
        margin-top: 150px
    .offering-img
        img
            height: 1030px
            object-fit: cover

.peek-into-section
    font-family: 'Space Grotesk'
    font-style: normal
    font-weight: 700
    font-size: 57.7895px
    line-height: 104.5%
    text-align: center
    color: #FFFFFF
    .Symbiote-data
        grid-gap: 20px
        gap: 20px
        display: flex
        justify-content: center
        align-items: center
        .menuItem
            background: rgba(68, 68, 68, 0.25)
            border: 1px solid #2F2F2F
            border-radius: 10px
            font-family: 'Space Grotesk'
            font-style: normal
            font-weight: 700
            font-size: 16px
            line-height: 104.5%
            text-align: center
            width: 170px
            height: 42px
            color: #FFFFFF
            &.selectedMenu
                background: linear-gradient(180deg, #4398FF 0%, #2479DF 100%)
                border-radius: 10px
                border: 1px solid #2479DF
.acceleration-dashboard-section
    position: relative
    z-index: 1
    h2
        text-align: center
        background: linear-gradient(90deg, #FFFFFF 21.71%, #4398FF 44.41%, #2479DF 76.88%)
        -webkit-background-clip: text
        -webkit-text-fill-color: transparent
        background-clip: text
        text-fill-color: transparent
    .blue-line
        background: linear-gradient(270deg, #4398FF 5.08%, rgba(255, 255, 255, 0) 112.4%)
        border-radius: 4.93956px
        height: 1.65px !important
        width: 100px
        display: flex
        top: 0%
        left: 0
        position: absolute
        -webkit-animation: linear infinite
        -webkit-animation-name: blue-line-text
        -webkit-animation-duration: 10s
        z-index: -1
        &.blue-line-2
            top: 10%
            animation: blue-line-text 5s linear infinite
        &.blue-line-3
            top: 20%
            animation: blue-line-text 4s linear infinite
        &.blue-line-4
            top: 30%
            animation: blue-line-text 3s linear infinite
        &.blue-line-5
            top: 40%
            animation: blue-line-text 9s linear infinite
        &.blue-line-6
            top: 50%
            animation: blue-line-text 7s linear infinite
        &.blue-line-7
            top: 60%
            animation: blue-line-text 8s linear infinite
        &.blue-line-8
            top: 70%
            animation: blue-line-text 10s linear infinite
        &.blue-line-9
            top: 80%
            animation: blue-line-text 6s linear infinite
        &.blue-line-10
            top: 90%
            animation: blue-line-text 4s linear infinite
    @keyframes blue-line-text
        0%
            left: 0%
        100%
            left: 100%

.sounds-good-section
    background: rgba(0, 0, 0, 0.8)
    padding: 50px
    h2
        text-align: center
        background: linear-gradient(90deg, #4398FF 7.71%, #2479DF 63.43%)
        -webkit-background-clip: text
        -webkit-text-fill-color: transparent
        background-clip: text
        text-fill-color: transparent
    img
        height: 25px
    p
        background: linear-gradient(90deg, #25252500 , #54C4FC33 , #2479DF , #00000000)
        padding: 5px 0
        text-align: center
    .service-card-border
        padding: 2px 2px
        border-radius: 42px
        position: relative
        z-index: 1
        overflow: hidden
        --borderWidth: 3px
        &::after
            content: ""
            position: absolute
            left: 0
            top: 0
            z-index: -1
            top: calc(-1 * var(--borderWidth))
            left: calc(-1 * var(--borderWidth))
            height: calc(100% + var(--borderWidth) * 2)
            width: calc(100% + var(--borderWidth) * 2)
            background: linear-gradient(320.73deg, #4398FF 30.29%, #2479DF 60.02%, #000000 84.65%)
            border-radius: calc(2 * var(--borderWidth))
            z-index: -1
            animation: animatedgradient 2s ease alternate infinite
            background-size: 300% 300%
        @keyframes animatedgradient
            0%
                background-position: 0% 25%

            50%
                background-position: 100% 50%

            100%
                background-position: 0% 25%
        .service-card-inner
            background: black
            margin: 0px
            padding: 22px
            border-radius: 42px
    .service-card
        padding: 50px 20px
        background: rgba(68, 68, 68, 0.25)
        border: 0.653239px solid #2F2F2F
        border-radius: 22.8633px
        text-align: center
        &.left-card
            background: transparent
            border: none
            border-radius: 0px
        .card-img
            img
                height: 70px
                margin-bottom: 30px
        h4
            background: linear-gradient(183.21deg, #86D7FF 17.32%, #2479DF 97.63%)
            -webkit-background-clip: text
            -webkit-text-fill-color: transparent
            background-clip: text
            text-fill-color: transparent
.left-part-background
    position: relative
    &.accelerate-hero-section
        &::before
            bottom: 0%
            transform: translateY(0%)
    &.peek-into-section
        &::before
            height: 100%
    &.new-hero-section
        &.launchpad
            &::before
                height: 80%
                bottom: -87%
                top: auto
                width: 35%
    &::before
        content: ""
        position: absolute
        left: 0px
        top: 50%
        transform: translateY(-50%)
        width: 100%
        height: 150%
        background-image: url(../../assests/images/homepage/how-we-work-bg.svg)
        background-size: contain
        background-repeat: no-repeat
        z-index: -1

.right-part-background
    position: relative
    &.accelerate-hero-section
        &::before
            bottom: 0%
            transform: translateY(0%)
    &.plunge-section
        &::before
            top: -50%
            left: auto
            right: -14%
            transform: translateY(0%)
    &::before
        content: ""
        position: absolute
        left: 0px
        top: 50%
        transform: translateY(-50%)
        width: 100%
        height: 150%
        background-image: url(../../assests/images/homepage/partner-rectangle-bg.svg)
        background-position: right
        background-size: contain
        background-repeat: no-repeat
        z-index: -1

@media screen and (max-width: 1600px)
    .accelerate-hero-section
        .accelerate-main-img
            max-height: 50px
        .counter-card
            padding: 20px
            .counter-img
                height: 45px
                width: 45px
                margin-top: -45px
    .comprehensive-section
        p
            width: 70%
        .offering-img
            img
                height: 965px
@media screen and (max-width: 1400px)
    .plunge-section
        .get-accelerated-btn
            border-radius: 12px
            font-size: 18.4px
            line-height: 24px
            padding: 8px 30px
        .counter-card
            background: rgba(68, 68, 68, 0.25)
            border: 1px solid #2F2F2F
            border-radius: 20px
            text-align: center
            padding: 20px
            .counter-img
                height: 40px
                width: 40px
                object-fit: contain
                margin: auto
                margin-top: -45px
                margin-bottom: 15px
    .comprehensive-section
        // h3
        //     font-size: 36px
    .sounds-good-section
        .service-card
            padding: 40px 20px
@media screen and (max-width: 1200px)
    .plunge-section
        .get-accelerated-btn
            border-radius: 12px
            font-size: 12.4px
            line-height: 24px
            padding: 4px 25px
    .comprehensive-section
        .offering-img
            img
                height: 900px
    .accelerate-hero-section
        .accelerate-main-img
            max-height: 50px
        .program-button
            margin-bottom: 60px
        .counter-card
            .counter-img
                height: 35px
                width: 35px
                margin-top: -40px
    .sounds-good-section
        .service-card
            .card-img
                img
                    margin-bottom: 20px
                    max-height: 60px
    .accelerate-program-section
        .projectDetails-top
            border-radius: 14.93px
            background: #000000D9
            border: 1px solid #2F2F2F
            padding: 15px
            .slick-slide
                .slide-content
                    width: 95% !important
                    margin: auto !important
                    img, video
                        border-radius: 28.6875px
                        height: 300px
                        min-height: 300px
                        width: 100%
                        object-fit: cover
        .projectDetails
            .tab-data-alignment
                .paragraph-new
                    line-height: 26px
            .css-1h9z7r5-MuiButtonBase-root-MuiTab-root, .css-1q2h7u5
                font-size: 12px !important
    .accelerate-program-section
        .projectDetails
            .tab-data-alignment 
                .css-19kzrtu
                    ul
                        li
                            line-height: 23px
@media screen and (max-width: 992px)
    .accelerate-hero-section
        .program-button
            margin-bottom: 60px
    .accelerate-hero-section
        .counter-card
            padding: 15px
            margin-bottom: 40px
            height: 146px
            display: flex
            flex-direction: column
            justify-content: space-between
            margin-bottom: 0
            .counter-img
                height: 30px
                width: 30px
                margin-top: -32px
                margin-bottom: 15px
            p
                padding: 0 10px
    .accelerate-program-section
        .projectDetails
            .css-35zqo5-MuiTabs-root, .css-1vr8b6v
                min-width: 150px !important
                height: 100% !important
                width: 150px
    .comprehensive-section
        .offering-img
            img
                height: 580px
        .text-mar
            margin-top: 100px
        p
            font-size: 10px
            width: 90%
        // h3
        //     font-size: 20px
    .sounds-good-section
        .service-card
            margin-bottom: 20px
            padding: 30px 15px
    .peek-into-section
        .Symbiote-data
            gap: 15px
            .menuItem
                width: 145px
                height: 36px
                font-size: 14px
    .left-part-background
        &.new-hero-section
            &.launchpad
                &::before
                    display: none
@media screen and (max-width: 767px)
    .accelerate-program-section
        .projectDetails
            .tab-data-alignment
                .css-19kzrtu
                    ul
                        li
                            line-height: 15px
    .accelerate-hero-section
        .accelerate-main-img
            max-height: 23px
        .blue-line
            width: 50px
            height: 1px
            &.blue-line-6
                top: 20%
            &.blue-line-7
                top: 10%
            &.blue-line-8
                top: 5%
            &.blue-line-9
                top: 8%
            &.blue-line-10
                top: 30%
        .counter-card
            border-radius: 12px
            p
                padding: 0
    .accelerate-program-section
        &.home-understande-program
            .projectDetails
                .css-35zqo5-MuiTabs-root, .css-1vr8b6v
                    min-width: 110px !important
                .css-1h9z7r5-MuiButtonBase-root-MuiTab-root, .css-1q2h7u5
                    padding-right: 35px
                .css-19kzrtu
                    padding: 10px
            .projectDetails-top
                padding: 10px
        h5
            span
                font-size: 16px
    .accelerate-hero-section
        padding-top: 15px
        .launch-text
            p
                font-size: 6.17092px
            img
                max-height: 20px
        .program-button
            margin-bottom: 40px
        .counter-card
            margin: 15px 0
            height: auto
    .accelerate-program-section
        .projectDetails-top
            padding: 10px
        .projectDetails
            .tab-data-alignment
                .paragraph-new
                    line-height: 18px
            .css-35zqo5-MuiTabs-root, .css-1vr8b6v
                min-width: 110px !important
                height: 100% !important
                width: 110px
            .css-1h9z7r5-MuiButtonBase-root-MuiTab-root, .css-1q2h7u5
                font-size: 10px !important
                padding: 8px 14px
                min-height: 35px !important
    .css-19kzrtu
        padding: 10px !important
    .comprehensive-section
        .text-mar
            margin-top: 80px
            p
                font-size: 8px
                width: 100%
        h3
            font-size: 14px

    .peek-into-section
        .Symbiote-data
            gap: 5px
            .menuItem
                width: fit-content
                font-size: 8px
                height: 16px
                border: 0.382978px solid #FFFFFF
                border-radius: 3.82978px
                font-weight: 400
                padding: 0 10px
                &.selectedMenu
                    border-radius: 3.82978px
    .sounds-good-section
        .service-card
            h4
                font-size: 18px
        p
            font-size: 10px
        .service-card
            padding: 30px 0px
        h2
            font-size: 23px
        img
            height: 15px
    .sounds-good-section
        padding: 30px
        .service-card
            border-radius: 15.2706px
        .service-card-border
            border-radius: 15.2706px
            .service-card-inner
                border-radius: 15.2706px
                padding: 30px
