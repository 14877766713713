.media-slider-main
    width: 100%
    overflow-x: auto
    .media-slider-container
        display: flex
        gap: 24px
        height: fit-content
        .slider-item
            cursor: pointer
            min-width: 400px
            max-width: 400px
            .youtube-video-item
                height: 220px
                width: 100%
                position: relative
                .disable-video-playback
                    position: absolute
                    top: 0
                    left: 0
                    width: 100%
                    height: 100%
                    z-index: 1
                .youtube-video, iframe, img
                    height: 100%
                    width: 100%
                    border-radius: 26px
                    overflow: hidden
                    object-fit: cover
            .title-item
                margin-top: 20px
                max-width: auto
                h4
                    height: 52px
                    font-size: 20px
                    display: flex
                    align-items: center
                    justify-content: center
            .description-item
                p
                    // min-height: 46px
                    font-size: 14px
                    display: -webkit-box
                    -webkit-line-clamp: 2
                    -webkit-box-orient: vertical
                    overflow: hidden
                    text-overflow: ellipsis

@media screen and (max-width: 767px)
    .media-slider-main
        .media-slider-container
            gap: 12px
            .slider-item
                min-width: 280px !important
                max-width: 280px !important
                .youtube-video-item
                    height: 150px
