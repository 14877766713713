.vc-profile-section
    background: #000000
    z-index: 1
    .dashboard-title
        height: 35px
    .user-profile-image
        height: 115px
        width: 115px
        border-radius: 50%
        position: relative
        overflow: hidden
        img
            width: 100%
            height: 100%
            object-fit: cover
    .blue-line
        background: linear-gradient(270deg, #4398FF 5.08%, rgba(255, 255, 255, 0) 112.4%)
        border-radius: 4.93956px
        height: 1.65px !important
        width: 100px
        display: flex
        top: 0%
        left: 0
        position: absolute
        -webkit-animation: linear infinite
        -webkit-animation-name: blue-half-line
        -webkit-animation-duration: 10s
        z-index: -1
        &.blue-line-2
            top: 10%
            animation: blue-half-line 5s linear infinite
        &.blue-line-3
            top: 20%
            animation: blue-half-line 4s linear infinite
        &.blue-line-4
            top: 30%
            animation: blue-half-line 3s linear infinite
        &.blue-line-5
            top: 40%
            animation: blue-half-line 9s linear infinite
        &.blue-line-6
            top: 50%
            animation: blue-half-line 7s linear infinite
        &.blue-line-7
            top: 60%
            animation: blue-half-line 8s linear infinite
        &.blue-line-8
            top: 70%
            animation: blue-half-line 10s linear infinite
        &.blue-line-9
            top: 80%
            animation: blue-half-line 6s linear infinite
        &.blue-line-10
            top: 90%
            animation: blue-half-line 4s linear infinite
    @keyframes blue-half-line
        0%
            left: 0%
        100%
            left: 30%

@media screen and (max-width: 1400px)
    .vc-profile-section
        .dashboard-title
            height: 35px
        .user-profile-image
            height: 95px
            width: 95px
            min-width: 95px
            min-height: 95px

@media screen and (max-width: 767px)
    .vc-profile-section
        .dashboard-title
            height: 30px
        .user-profile-image
            height: 70px
            width: 70px
            min-width: 70px
            min-height: 70px

@media screen and (max-width: 992px)
    @keyframes animName
        0%
            left: 0
            transform: rotateY(0deg)

        50%
            left: 50%
            transform: rotateY(90deg)

        100%
            left: 0
            transform: rotatY(180deg)
@media screen and (max-width: 767px)
    @keyframes animName
        0%
            left: 0
            transform: rotateY(0deg)

        50%
            left: 100%
            transform: rotateY(90deg)

        100%
            left: 0
            transform: rotateY(180deg)
