@import "../../../../../../../variables.sass"

.progress-table
    .partner-list-data
        table
            thead
                th
                    &:first-child
                        padding-left: 15px !important
.progress-table-data
    margin-top: 30px
    padding: 36px 28px
    background: $card-background
    // border: 1px solid #2F2F2F
    border-radius: 25.93px
    .no-project-found
        width: 100%
        min-height: 50vh
        display: flex
        justify-content: center
        align-items: center
        font-size: 26px
    .css-18fq97s-MuiTableContainer-root, .css-1fadi4t
        max-height: 440px
    table
        border: none
        min-height: 100px
        // max-height: 500px
        // min-height: 500px
        // overflow: scroll
        thead
            tr
            th
                padding: 15px
                color: #fff
                font-family: 'Space Grotesk'
                font-style: normal
                font-weight: 300
                font-size: 16px
                line-height: 20px
                background: $card-background
                border-bottom: 0 !important
            th:first-child
                border-radius: 20px 0 0 20px
            th:last-child
                border-radius: 0 20px 20px 0
        tbody
            // height: 500px
            // max-height: 500px
            // min-height: 500px
            // overflow: scroll
            tr
                vertical-align: top
                td, .css-1ex1afd-MuiTableCell-root
                    padding: 18px
                    color: #fff
                    font-family: 'Space Grotesk'
                    font-style: normal
                    font-weight: 300
                    font-size: 16px
                    line-height: 20px
                    border-bottom: 0 !important
    .css-1fw5qro-MuiPaper-root, .css-1cf86vr
        background-color: transparent !important
        box-shadow: none !important
    .social-link-table
        display: flex
        align-items: center
        justify-content: center
        gap: 10px
        padding-left: 0
        margin-bottom: 0
        li
            list-style: none
            a
                width: 21px
                height: 21px
                min-width: 21px
                min-height: 21px
                color: black
                background: #4398FF
                display: flex
                align-items: center
                justify-content: center
                text-decoration: none
                border-radius: 50%
    .table-description
        display: -webkit-box
        -webkit-line-clamp: 2
        -webkit-box-orient: vertical
        overflow: hidden
        text-overflow: ellipsis

@media screen and (max-width: 767px)
    .progress-table-data
        padding: 10px
        border-radius: 18px
        table
            thead
                th
                    padding: 10px
                    font-size: 12px

            tbody
                tr
                    td, .css-1ex1afd-MuiTableCell-root
                        overflow-wrap: anywhere
                        font-size: 12px
                        padding: 10px
                        span
                            display: -webkit-box
                            -webkit-line-clamp: 5
                            -webkit-box-orient: vertical
                            overflow: hidden
                            text-overflow: ellipsis
