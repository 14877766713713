@media screen and (max-width: 992px)
    .vc-dashbord-main-page
        .dashboard-tabs-section
            .profile-view-tabs
                .css-1h9z7r5-MuiButtonBase-root-MuiTab-root, .css-1q2h7u5
                    padding: 12px 30px 12px 20px !important
                    margin: 15px 0 !important
                    &::after
                        right: 8px
                        height: 15px
                        width: 15px
                .css-35zqo5-MuiTabs-root, .css-1vr8b6v
                    min-width: 170px !important

@media screen and (max-width: 767px)
    .vc-dashbord-main-page
        .dashboard-tabs-section
            .profile-view-tabs
                .css-1h9z7r5-MuiButtonBase-root-MuiTab-root, .css-1q2h7u5
                    padding: 10px 15px 10px 15px !important
                    margin: 10px 0 !important
                    &::after
                        right: 4px
                        height: 10px
                        width: 10px
                .css-35zqo5-MuiTabs-root, .css-1vr8b6v
                    min-width: 115px !important
                    padding-top: 0px !important
