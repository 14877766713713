.vc-dashboard-section
    .dashboard-profile-section
        // .dashboard-title
        //     height: 35px
        // .profile-banner
        //     // position: absolute
        //     // width: 100%
        //     position: relative
        //     z-index: -1
        //     &::after
        //         content: ""
        //         top: 0
        //         left: 0
        //         position: absolute
        //         width: 50%
        //         height: 100%
        //         // z-index: -2
        //         background: linear-gradient(89.89deg, #972E15 19.17%, rgba(217, 217, 217, 0) 94.12%)
        //     img
        //         object-fit: cover
        .profil-text
            // position: relative
            // top: 260px
            padding: 2% 8%
            background: black
            margin: 0px
            max-width: 100%
            .profil-text-section
                display: flex
                justify-content: space-between
                align-items: flex-end
                margin-top: -120px
                .user-profile
                    border-radius: 50%
                    padding: 10px
                    width: 215.8px
                    height: 215.8px
                    object-fit: cover
                .deshboard-social
                    i
                        font-size: 25px
                        color: #000
                        background: #2479DF
                        border-radius: 50%
                        padding: 5px
                        margin-right: 10px
                        cursor: pointer
                .duelist-king
                    font-family: 'Space Grotesk'
                    font-style: normal
                    font-weight: 300
                    font-size: 38.8571px
                    line-height: 50px
                    color: #FFFFFF
    .profile-section
        .back-btn
            p
                text-decoration: underline
        .your-profile
            h2
                font-family: 'Space Grotesk'
                font-style: normal
                font-weight: 400
                font-size: 60.8947px
                line-height: 104.5%
                text-align: center
                color: #FFFFFF
        .public-btn
            button
                i
                    color: #4398FF
    .form-slider
        display: flex
        justify-content: center
        .percentage-slider
            gap: 15px
            .CircularProgressbar
                width: 70px
.investment-charts
    border-radius: 25.93px
    background: linear-gradient(310deg, #4398FF 30.29%, #2479DF 60.02%, #000000 84.65%)
    padding: 2px
    --borderWidth: 3px
    position: relative
    z-index: 1
    overflow: hidden
    &::after
        content: ""
        position: absolute
        left: 0
        top: 0
        z-index: -1
        top: calc(-1 * var(--borderWidth))
        left: calc(-1 * var(--borderWidth))
        height: calc(100% + var(--borderWidth) * 2)
        width: calc(100% + var(--borderWidth) * 2)
        background: linear-gradient(320.73deg, #4398FF 30.29%, #2479DF 60.02%, #000000 84.65%)
        border-radius: calc(2 * var(--borderWidth))
        z-index: -1
        animation: animatedgradient 2s ease alternate infinite
        background-size: 300% 300%
    .investment-charts-inner
        background: #000000
        border-radius: 25.93px
        padding: 30px
    #chart
        div
            // max-height: 300px !important
            // min-height: 300px !important
            // height: 400px !important
            max-width: 600px
    .apexcharts-toolbar
        .apexcharts-menu-icon
            display: none
    .apexcharts-text
        tspan
        font-family: 'Space Grotesk'
        font-style: normal
        font-weight: 700
        font-size: 13px
        line-height: normal
        fill: #FFFFFF

.investment-charts
    .duelist-profile
        margin-top: 50px
        div
            margin-bottom: 53px
            img
                height: 54.12px
                width: 54.12px
                max-width: 54.12px
                max-height: 54.12px
@media screen and (max-width: 1200px)
    .investment-charts
        border-radius: 18.93px
        .investment-charts-inner
            border-radius: 18.93px
            padding: 15px
        .duelist-profile
            margin-top: 55px
            div
                margin-bottom: 55px
                img
                    height: 45.12px
                    width: 45.12px
                    max-width: 45.12px
                    max-height: 45.12px
                .heading-new-6
                    font-size: 18.8424px
@media screen and (max-width: 992px)
    .investment-charts
        border-radius: 18.93px
        .investment-charts-inner
            border-radius: 18.93px
            padding: 15px
        .duelist-profile
            margin-top: 43px
            div
                margin-bottom: 37px
                img
                    height: 35.12px
                    width: 35.12px
                    max-width: 35.12px
                    max-height: 35.12px
                .heading-new-6
                    font-size: 13.8424px

@media screen and (max-width: 767px)
    .investment-charts
        border-radius: 18.93px
        .investment-charts-inner
            border-radius: 18.93px
            padding: 10px
        .duelist-profile
            margin-top: 20px
            div
                margin-bottom: 10px
                img
                    height: 18px
                    width: 18px
                    max-width: 18px
                    max-height: 18px
                .heading-new-6
                    font-size: 12px
                    line-height: 14px
        .apexcharts-text
            font-size: 10px
