.page-not-found {
    height: 100vh;
    overflow: hidden;
    overflow-y: scroll;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
}

.page-not-found .page_404 {
    padding: 40px 0;
    background: #fff;
    font-family: 'Arvo', serif;
    width: 100%;
}

.page-not-found .page_404 img {
    width: 100%;
}

.page-not-found .four_zero_four_bg {

    background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
    height: 400px;
    background-position: center;
}

.page-not-found .four_zero_four_bg h1 {
    font-size: 80px;
}

.page-not-found .four_zero_four_bg h3 {
    font-size: 80px;
}

.page-not-found .link_404 {
    color: #fff !important;
    padding: 10px 20px;
    background: #39ac31;
    margin: 20px 0;
    display: inline-block;
    text-decoration: none;
}

.page-not-found .contant_box_404 {
    margin-top: -50px;
}