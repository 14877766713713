.project-profile-view-container
    z-index: 1
    &:after
        content: ""
        position: absolute
        left: -38%
        bottom: -130%
        background-image: url('../../../../../assests/images/accelerate/pages/deshbord-bg-light.svg')
        background-position: center
        background-size: contain
        background-repeat: no-repeat
        width: 100%
        height: 100%
        z-index: -1
        opacity: 0.15
        transform: scale(1.5)
    &::before
        content: ""
        position: absolute
        right: -11%
        top: 80%
        background-image: url('../../../../../assests/images/accelerate/pages/deshbord-bg-light.svg')
        background-position: center
        background-size: contain
        background-repeat: no-repeat
        width: 100%
        height: 100%
        z-index: -1
        opacity: 0.3
        transform: scale(1.5)

.public-view-section
    position: relative
    z-index: 2
    .profile-section
        .public-btn
            button
                background: linear-gradient(270deg, #4398FF 0%, rgba(84, 196, 252, 0) 100%)
                border: 2px solid #4398FF
                border-radius: 15px
                i
                    color: #ffffff
    .edit-form
        p
            text-decoration: underline
.profile-details
    background: rgba(68, 68, 68, 0.25)
    border: 1px solid #2F2F2F
    border-radius: 18px
    padding: 30px 120px
    .duelist-img
        border-radius: 50%
        padding: 0 10px
        margin-right: 5px
        img
            width: 110px
            height: 110px
            border-radius: 50%
            object-fit: cover
    .duelist-name
        margin-right: 16px
        h3
            font-family: 'Space Grotesk'
            font-style: normal
            font-weight: 700
            font-size: 38.8571px
            line-height: 50px
            color: #FFFFFF
    .form-slider
        .percentage-slider
            .CircularProgressbar-text
                font-size: 30px
    .view-btn-section
        .deshboard-social
            ul
                padding-left: 0
                display: flex
                align-items: center
                gap: 15px
                margin-bottom: 0
                li
                    list-style: none
                    a
                        font-size: 18px
                        color: #000
                        background: #4398FF
                        border-radius: 50%
                        height: 25px
                        width: 25px
                        min-height: 25px
                        min-width: 25px
                        display: flex
                        align-items: center
                        justify-content: center
                        padding: 0
                        text-decoration: none
                        cursor: pointer
                        gap: 15px
    .counter-section
        .counters-card
            background: #0A1F38
            border: 1px solid #2F2F2F
            border-radius: 18px
            padding: 25px 35px
            h2
                font-family: 'Space Grotesk'
                font-style: normal
                text-transform: uppercase
                background: linear-gradient(90.03deg, #4398FF -5.59%, #2479DF 65.86%)
                -webkit-background-clip: text
                -webkit-text-fill-color: transparent
                background-clip: text
                text-fill-color: transparent
    .event-section
        .event-bg
            background: #44444440
            border: 1px solid #2F2F2F
            border-radius: 18px
            padding: 25px
            height: 88%
            .event-card
                background: #153E70
                border-radius: 21px
                padding: 20px
            .courses-section
                img
                    border-radius: 5.33407px
                    height: 55px
                    width: 100px
                    object-fit: cover
@media screen and (max-width: 1200px)
    .profile-details
        padding: 30px 40px
        .duelist-img
            img
                width: 90.8px
                height: 90.8px
        .duelist-name
            h3
                font-size: 28.8571px
                line-height: 130%
        .view-btn-section
            .new-primary-button
                font-size: 12px

@media screen and (max-width: 992px)
    .profile-details
        padding: 25px
        .duelist-img
            img
                width: 70.8px
                height: 70.8px
        .duelist-name
            h3
                font-size: 20.8571px
            .new-primary-button-small
                font-size: 10px

@media screen and (max-width: 767px)
    .project-profile-view-container
        &::after
            bottom: 10%
        &::before
            top: 40%

    .profile-details
        padding: 10px
        .score-profile-container
            margin-bottom: 16px
        .duelist-img
            margin-bottom: 20px
            img
                width: 83.56px
                height: 83.56px
        .duelist-name
            margin-bottom: 20px
            h3
                font-size: 25.32px
            .new-primary-button-small
                font-size: 10px
                line-height: 90.5%
        .view-btn-section
            .new-primary-button
                font-size: 10px
            .deshboard-social
                i
                    font-size: 12px
                    padding: 2px
        .counter-section
            .counters-card
                padding: 15px 20px
        .event-section
            .event-bg
                height: auto
                padding: 10px
                .event-card
                    padding: 10px
                    border-radius: 13.1809px
                .courses-section
                    img
                        height: 33px
                        width: 60px
                        border-radius: 3.1353px

        .form-slider
            .percentage-slider
                .CircularProgressbar
                    width: 40px
