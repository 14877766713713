.addShadow
  ::before,
  ::after
    content: ""
    position: absolute
    left: 0
    right: 0
    height: 120px
    pointer-events: none
    animation: fadeIn 5s

  ::before
    position: fixed
    top: 0

  ::after
    bottom: 0
    content: ""
    position: fixed
    height: 250px
    background: linear-gradient(to bottom,rgba(0, 0, 0, 0),rgba(0, 0, 0, 0.32))
    pointer-events: none
    animation: fadeIn 2s
@keyframes fadeIn 
  0%  
    opacity: 0
  100%  
    opacity: 1

    