.just-in
    display: flex
    align-items: center
    gap: 40px
    h1
        font-family: 'Space Grotesk'
        font-style: normal
        font-weight: 700
        font-size: 72.3784px
        line-height: 104.5%
        background: linear-gradient(180deg, #4398FF 0%, #2378DE 100%)
        -webkit-background-clip: text
        -webkit-text-fill-color: transparent
        background-clip: text
        text-fill-color: transparent
    .table-filter-content
        margin-top: 0
.vc-slider-card
    .vc-card
        width: 95%
.respond-reject-btn
    display: flex
    align-items: center
    justify-content: space-between
    button
        font-family: 'Space Grotesk'
        font-style: normal
        font-weight: 700
        font-size: 15.687px
        line-height: 20px
        text-align: center
        color: #FFFFFF
        padding: 4px 22px
        background: transparent
        width: 48%
        transition: all 0.3s ease-in-out
        &.respond-btn
            border: 1px solid #4398FF
            border-radius: 18.9552px
            &:hover
                color: #4398FF
        &.reject-btn
            border: 1px solid #FF0000
            border-radius: 18.9552px
            &:hover
                color: #FF0000

@media screen and (max-width: 1600px)
    .just-in
        h1
            font-size: 58px

@media screen and (max-width: 1200px)
    .just-in
        h1
            font-size: 46px

@media screen and (max-width: 992px)
    .just-in
        h1
            font-size: 40px
    .respond-reject-btn
        justify-content: center
        gap: 10px
        button
            width: 40%
            font-size: 12px
            line-height: 15px

@media screen and (max-width: 767px)
    .just-in
        display: block
        text-align: center
        h1
            font-size: 40px
