.blog-container {
  background-color: #ffffff;
  min-height: 600px;

  .container-loader {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  #news-post-container {
    width: 100% !important;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    b,
    span,
    label {
      font-family: "Space Grotesk" !important;
    }
    p {
      font-weight: 400 !important;
    }
    span {
      font-weight: 400 !important;
    }
  }
}

.blogs-container {

  .selected-tag-container {
    padding: 0 2%;
    max-width: 1400px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #ffffff;
    height: 300px;
    position: relative;
    overflow: hidden;
    .tag-page-heading {
      position: relative;
      z-index: 10;
    }
    .blog-image-container {
      position: absolute;
      top: -30%;
      right: 0px;
      z-index: 9;
      .blog-bg-img {
        width: 800px;
        opacity: 0.7;
      }
    }
  }

  .heading-and-logo {
    padding: 0 2%;
    max-width: 1400px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #ffffff;
    height: 600px;

    .info-section {
      width: 50%;
      max-width: 500px;
    }

    .blogs-icon-section {
      width: 50%;
      height: 100%;

      .image-container {
        position: relative;

        .blog-bg-img {
          position: absolute;
          top: -104px;
          left: -96px;
          width: 117%;
          opacity: 0.7;
        }

        .element-img {
          position: absolute;
          top: 57px;
          left: 14px;
          width: 68%;
        }
      }
    }

  }

  .posts-content-container {
    background: #ffffff;
    position: relative;
    padding: 2.4rem 2%;

    .latest-posts-container {}

    .trending-posts-container {
      .trending-container {
        width: 100%;
        display: flex;
        overflow-x: auto;
        gap: 3%;
        -ms-overflow-style: none;
        /* Internet Explorer 10+ */
        scrollbar-width: none;

        /* Firefox */
        .trending-post-item {
          width: 33%;
          min-width: 500px;
        }

        .container {}

        &::-webkit-scrollbar {
          display: none;
          /* Safari and Chrome */
        }
      }

      .actions-btn-container {
        max-width: 120px;
        justify-content: space-between;
        display: flex;
        margin: auto;
        margin-top: 3rem;

        img {
          cursor: pointer;
        }
      }
    }

    .collections-container {
      .blog-collection-item {
        height: 120px;
        background: #d5d5d5;
        border-radius: 24px;
        padding: 2%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        color: black;

        span {
          font-family: "Space Grotesk";
          font-size: 2rem;
          font-weight: 600;
          text-transform: capitalize;
        }
        &:hover {
          color: white;
          background: #5b91f3;
        }
      }
    }

    .all-posts-container {}
  }
}

@media screen and (max-width: 1100px) {
  .blogs-container {
    .heading-and-logo {
      padding: 0 2%;
      height: 500px !important;

      .info-section {
        max-width: 400px;
      }

      .blogs-icon-section {
        .image-container {
          .blog-bg-img {
            top: -56px;
            left: -76px;
          }

          .element-img {
            top: 64px;
            left: 34px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .blogs-container {
    .heading-and-logo {
      display: flex;
      flex-direction: column-reverse;
      height: 500px !important;

      .info-section {
        position: relative;
        z-index: 2;
        width: 100%;
        padding-bottom: 24px;
      }

      .blogs-icon-section {
        width: 100%;
        height: 400px;

        .image-container {
          position: relative;

          .blog-bg-img {
            top: -45px;
            left: 9%;
            width: 80%;
          }

          .element-img {
            top: 54px;
            left: 26%;
            width: 48%;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .blogs-container {
    .selected-tag-container {
      height: 200px;
      .blog-image-container {
        top: -0%;
      }
    }
    .heading-and-logo {
      height: 630px !important;

      .info-section {
        position: relative;
        z-index: 2;
        width: 100%;
        padding-bottom: 24px;

        h1 {
          text-align: center;
        }

        span {
          display: block;
          line-height: 18px;
          text-align: center;
          font-size: 14px;
        }
      }

      .blogs-icon-section {
        width: 100%;

        .image-container {

          .blog-bg-img {
            top: -40px;
            left: -4%;
            width: 120%;
          }

          .element-img {
            top: 27px;
            left: 9%;
            width: 76%;
          }
        }
      }
    }
    .trending-posts-container {
      .trending-post-item {
        width: 100% !important;
        min-width: 100% !important;
      }
    }
    .collections-container {
      .blog-collection-item {
        height: 80px !important; 
        span {
          font-size: 1.2rem !important;
        }
      }
    }
  }
}