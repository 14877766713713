@import '../../../../../variables.sass'

.partner-network-page
    .industries-filter-main
        background: $card-background
        // border: 1px solid #2F2F2F
        border-radius: 18px
        padding: 20px 10px
        max-width: 99%
        margin: auto
        .deselect-all-item
            font-size: 14px
            text-align: center
            margin-top: 14px
            color: white
            text-decoration: underline
            cursor: pointer
    .industries-filter-scrollable
        padding-bottom: 20px
        overflow-x: auto
    .industries-filter-container
        display: flex
        // flex-wrap: wrap
        gap: 10px
        // justify-content: center

        .industries-filter-item
            span
                background: $card-item-background
                border-radius: 10px
                // font-weight: 500
                font-size: 14px
                line-height: 104.5%
                text-align: center
                color: #FFFFFF
                height: 40px
                width: max-content
                padding: 10px
                box-sizing: border-box
                display: block
                cursor: pointer
                &:hover
                    background: $card-item-hover-background
                &.selected
                    background: $text-primary-color
                    cursor: default
                &.not-selected
    .current-partner-list-content
        .network-catogory
            background: $card-background
            // border: 1px solid #2F2F2F
            border-radius: 18px
            padding: 1%
            .network-category-container
                display: flex
                overflow-x: scroll
                .network-category-item
                    background: $card-item-background
                    border-radius: 10px
                    padding: 4px 10px
                    width: max-content
                    margin-right: 10px
                    .form-check-input
                        background-color: transparent
                        border: 1px solid #fff
                        border-radius: 50%
                        cursor: pointer
                        &:checked[type=checkbox]
                            background-image: none
                            background: #fff
                        &:focus
                            box-shadow: none
                    .form-check-label
                        cursor: pointer
        .top-card-content
            .card-content-part
                display: flex
                justify-content: center
                flex-wrap: wrap
                gap: 10px
                .big-content
                    width: 100%
                    margin: 0 20px
                    .list-card
                        .center-dropdown
                            .dropdown-option
                                display: grid
                                grid-template-columns: auto auto auto
                                button
                                    min-width: 110px
                .same-content
                    min-width: 32%
            .list-card
                background: $card-background
                // border: 1px solid #2F2F2F
                border-radius: 18px
                padding: 20px
                .center-dropdown
                    .dropdown-option
                        display: grid
                        grid-template-columns: auto auto
                        gap: 10px
                        justify-content: space-around
                        position: relative
                        height: 57px
                        overflow: hidden
                        border-radius: 15px
                        padding: 10px
                        &.active
                            height: auto
                            overflow: initial
                        button
                            background: $card-item-background
                            border: 1px solid transparent
                            border-radius: 10px
                            font-family: 'Space Grotesk'
                            font-style: normal
                            font-weight: 400
                            font-size: 14px
                            line-height: 104.5%
                            text-align: center
                            color: #FFFFFF
                            height: 40px
                            min-width: 90px
                            padding: 5px 10px
                            &:hover
                                background: #303030
                            &.active
                                background: $text-primary-color
                                &:hover
                                    background: $text-primary-color
        .bottom-content
            .partner-list-data
                .social-link-table
                    li
                        a
                            background: #fff
                table
                    thead
                        th
                            background: $card-background !important

@media screen and (max-width: 1600px)
    .partner-network-page
        .current-partner-list-content
            .top-card-content
                .list-card
                    .center-dropdown
                        .dropdown-option
                            height: 43px
                            overflow: hidden
                            border-radius: 0
                            padding: 0px
                            button
                                min-width: 90px
                                padding: 4px 5px
                                height: 37px
                .card-content-part
                    .big-content
                        .list-card
                            .center-dropdown
                                .dropdown-option
                                    button
                                        min-width: 90px
@media screen and (max-width: 1300px)
    .partner-network-page
        .current-partner-list-content
            .network-catogory
                padding: 25px
                .network-category-item
                    .form-check-label
                        font-size: 13px
            .top-card-content
                .list-card
                    padding: 15px
                    .center-dropdown
                        .dropdown-option
                            button
                                min-width: 80px
                                height: 34px
                                font-size: 12px
                .card-content-part
                    .big-content
                        .list-card
                            .center-dropdown
                                .dropdown-option
                                    button
                                        min-width: 80px

@media screen and (max-width: 992px)
    .partner-network-page
        .current-partner-list-content
            .top-card-content
                .card-content-part
                    flex-wrap: wrap
                    gap: 15px
                    .same-content
                        width: 36%
                    .big-content
                        width: 60%
                        margin: 0

@media screen and (max-width: 767px)
    .partner-network-page
        .current-partner-list-content
            .network-catogory
                padding: 10px
                .network-category-item
                    padding: 7px 10px
                    .form-check
                        display: flex
                        align-items: center
                        margin-bottom: 0
                        input
                            margin-top: 0.12em
                    .form-check-label
                        // font-size: 10px
                        // line-height: 12px
            .top-card-content
                .card-content-part
                    .same-content
                        width: 100%
                    .big-content
                        width: 100%
                .list-card
                    border-radius: 11.6765px
                    .center-dropdown
                        .dropdown-option
                            height: 30px
                            button
                                min-width: 80px
                                height: 27px
                                font-size: 9px
