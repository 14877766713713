header
    padding: 10px 0
    background: transparent
    position: relative
    z-index: 1
    .header-link-navigation
        color: white
        margin-right: 26px
        text-decoration: none
        font-size: 18px
        font-weight: 400
        &:hover
            color: #4398FF
    .accelerate-icon-item
        width: 166px
        height: 40px
        margin-left: 14px
        padding-top: 4px
        display: flex

@media screen and (max-width: 767px)
    header
        .accelerate-icon-item
            width: 120px
