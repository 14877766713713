
.vc-dashbord-main-page
    position: relative
    // overflow: hidden
    .drawer-tab-container
        min-width: 300px !important
        height: 100% !important
        .MuiTabs-indicator
            display: none
        // .MuiTabs-flexContainerVertical
        //     margin-top: 40px !important
        .tab-items
            margin: 20px 0 !important
            color: white !important
            text-align: left !important
            align-items: baseline !important
            min-height: 35px !important
            font-weight: 600
            font-size: 17px
            text-transform: capitalize
            &.Mui-selected
                position: relative
                background: linear-gradient(90.11deg, #4398FF 2.47%, #2479DF 73.06%)
                -webkit-background-clip: text
                -webkit-text-fill-color: transparent
                &::before
                    content: ''
                    position: absolute
                    left: 0
                    top: 0
                    height: 100%
                    width: 4px
                    border-radius: 16px
                    background: linear-gradient(180deg, #4398FF 0%, #2479DF 100%)
    // .dashboard-tabs-section
    //     .css-lfwcke-MuiTabs-flexContainer, .css-j7qwjs
    //         margin-top: 20px !important
    //     .css-1h9z7r5-MuiButtonBase-root-MuiTab-root, .css-1q2h7u5
    //         font-weight: 700
    //         padding-left: 40px
    //         min-height: 35px !important
    //         text-transform: capitalize
    //         color: #FFF
    //         font-size: 18px
    //         font-family: Space Grotesk
    //         font-style: normal
    //         font-weight: 700
    //         line-height: 104.5%
    //     .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected, .css-1q2h7u5.Mui-selected
    //         border-radius: 45px 0px 0px 45px !important
    // &:after
    //     content: ""
    //     position: absolute
    //     right: 0
    //     left: 0
    //     bottom: -30%
    //     background-image: url('../../../assests/images/accelerate/pages/deshbord-bg-light.svg')
    //     background-position: center
    //     background-size: contain
    //     background-repeat: no-repeat
    //     width: 100%
    //     height: 100%
    //     z-index: -1
    //     opacity: 0.2
    // &::before
    //     content: ""
    //     position: absolute
    //     right: -50%
    //     top: -50%
    //     background-image: url('../../../assests/images/accelerate/pages/deshbord-bg-light.svg')
    //     background-position: center
    //     background-size: contain
    //     background-repeat: no-repeat
    //     width: 100%
    //     height: 100%
    //     z-index: -1
    //     opacity: 0.2
.form-section
    .public-btn
        button
            i
                color: #4398FF
                margin-right: 5px
        &:hover
            i
                color: #fff
.connection-count
    display: flex
    align-items: center
    justify-content: center
    font-size: 18px
    font-family: Space Grotesk
    font-style: normal
    font-weight: 700
    line-height: 104.5%
    .border-round-count
        color: #FFF
        width: 28px
        height: 28px
        display: flex
        align-items: center
        justify-content: center
        border: 1px solid #4398FF
        border-radius: 50%

@media screen and (max-width: 1024px)
    .vc-dashbord-main-page
        .drawer-tab-container
            .tab-items
                margin: 4px 0 !important
            .MuiTabs-flexContainerVertical
                margin-top: 0px !important