.dashbord-main-page
    position: relative
    // &::after
    //     content: ""
    //     position: absolute
    //     right: 0
    //     left: 0
    //     bottom: -8%
    //     background-image: url('../../../assests/images/accelerate/pages/deshbord-bg-light.svg')
    //     background-position: center
    //     background-size: contain
    //     background-repeat: no-repeat
    //     width: 88%
    //     height: 82%
    //     z-index: -2
    //     opacity: 0.2
    .mobile-responsive-tab
        width: 300px
    .drawer-tab-container
        min-width: 300px !important
        height: 100% !important
        .MuiTabs-indicator
            display: none
        .MuiTabs-flexContainerVertical
            margin-top: 120px !important
        .tab-items
            margin: 20px 0 !important
            color: white !important
            text-align: left !important
            align-items: baseline !important
            min-height: 35px !important
            font-weight: 600
            font-size: 17px
            text-transform: capitalize
            &.Mui-selected
                position: relative
                background: linear-gradient(90.11deg, #4398FF 2.47%, #2479DF 73.06%)
                -webkit-background-clip: text
                -webkit-text-fill-color: transparent
                &::before
                    content: ''
                    position: absolute
                    left: 0
                    top: 0
                    height: 100%
                    width: 4px
                    border-radius: 16px
                    background: linear-gradient(180deg, #4398FF 0%, #2479DF 100%)

.demo-title
    background: #222222
    padding: 10px 0
    justify-content: center
    text-align: center
    img
        margin-right: 15px
    span
        margin-right: 5px
#scroll-start-element
    padding-top: 3rem
.dashboard-tabs-section
    // .css-19kzrtu
    //     max-height: 1600px
    //     overflow-y: auto
    //     overflow-x: hidden
    .container
        max-width: 1400px !important
        overflow: hidden

    .profile-name
        margin-bottom: -100px
        position: relative
        z-index: 11
        .drawer-company-name
            display: -webkit-box
            -webkit-line-clamp: 2
            -webkit-box-orient: vertical
            overflow: hidden
            text-overflow: ellipsis
        &.mobile-view
            display: none
        // h3
        //     font-family: 'Space Grotesk'
        //     font-style: normal
        //     font-weight: 300
        //     font-size: 38.8571px
        //     line-height: 50px
        //     color: #FFFFFF
        .CircularProgressbar
            width: 40px
            margin: 0 5px 0 20px
    // .css-19kzrtu
    //     max-height: 500px
    //     overflow-y: scroll
    //     width: 100%
    .MuiTableCell-root
        border-bottom: none !important
        color: white !important
    // .css-11xur9t-MuiPaper-root-MuiTableContainer-root, .css-13xy2my
    //     background: transparent !important
    //     box-shadow: none !important
    .table-color-bg
        position: relative
        .table-color-bg-content
            position: absolute
            top: 0
            height: 100%
            width: 10px
    .table-color-bar
        display: flex
        align-items: center
        justify-content: center
        position: relative
        .malti-color-progress-bar
            transform: rotate(270deg)
            width: 250px
            position: absolute
            left: 78px
            bottom: -60px
            height: -webkit-fill-available
        .table-bar
            padding-left: 50px
.mobile-tab-btn
    display: none
    .mobile-icon
        i
            font-size: 20px
            color: white
.tab-close-icon
    display: none
    position: absolute
    right: 14px
    top: 8px
    font-size: 20px
    color: white

@media screen and (max-width: 1400px)
    .dashboard-tabs-section
        .css-35zqo5-MuiTabs-root, .css-1vr8b6v
            min-width: 250px !important
@media screen and (max-width: 1024px)
    .dashbord-main-page
        .drawer-tab-container
            .tab-items
                margin: 4px 0 !important
            .MuiTabs-flexContainerVertical
                margin-top: 0px !important

    .mobile-tab-btn
        display: block
    .mobile-responsive-tab
        opacity: 0 !important
        visibility: hidden !important
        display: none !important
        width: 300px !important
        overflow: hidden
        &.active
            opacity: 1 !important
            visibility: visible !important
            position: fixed
            left: 0
            background: #000000
            height: 100%
            z-index: 11
            top: 0
            width: 30%
            transition: all .5s ease
            display: block !important
            padding: 0 0 0 16px !important
            transform: translate(0)
    .dashboard-tabs-section
        .css-35zqo5-MuiTabs-root, .css-1vr8b6v
            min-width: 280px !important
            padding-top: 35px !important
        .profile-name
            margin-bottom: 0
            display: none
            &.mobile-view
                display: flex
                align-items: center
                justify-content: space-between
        .css-lfwcke-MuiTabs-flexContainer, .css-j7qwjs
            margin-top: 0 !important
        .css-1h9z7r5-MuiButtonBase-root-MuiTab-root, .css-1q2h7u5
            margin: 5px 0 !important
    .tab-close-icon
        display: block

@media screen and (max-width: 992px)
    .dashboard-tabs-section
        .css-19kzrtu
            padding: 0px !important
            padding-top: 10px !important
    .mobile-responsive-tab
        &.active
            width: 40%

@media screen and (max-width: 767px)
    .mobile-responsive-tab
        &.active
            width: 100%
    .dashboard-tabs-section
        .drawer-tab-container

        .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected, .css-1q2h7u5.Mui-selected
            max-width: 100% !important
    .dashboard-tabs-section
        .container
            padding: 0
    // .dashbord-main-page
    //     &::after
    //         content: ""
    //         position: absolute
    //         left: -20%
    //         top: 12%
    //         transform: translateY(-50%)
    //         width: 100%
    //         height: 150%
    //         background-image: url(../../../assests/images/homepage/staking-bg-img.svg)
    //         background-size: contain
    //         background-repeat: no-repeat
    //         z-index: -4
    //         opacity: 0.8
