.project-progress
    .update-progres
        margin: 40px 0
        select
            -webkit-appearance: none
            -moz-appearance: none
            background: transparent
            background-image: url("data:image/svg+xml;utf8,<svg fill='cornflowerblue' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>")
            background-repeat: no-repeat
            background-position-x: 97%
            background-position-y: 7px
            padding: 10px 15px
            background-color: #000000ba
            border-radius: 13px
            position: relative
            font-family: 'Space Grotesk'
            font-style: normal
            font-weight: 300
            font-size: 14px
            line-height: 18px
            border: 1px solid #4398FF
            color: #FFFFFF
            width: 80%
            option
                background: black
                color: white
                font-family: 'Space Grotesk'
                font-style: normal
                font-weight: 300
                font-size: 14px
                line-height: 18px
        textarea
            font-family: 'Space Grotesk'
            font-style: normal
            font-weight: 300
            font-size: 14px
            line-height: 18px
            border: 0.680412px solid #FFFFFF
            border-radius: 13.6082px
            background: #000000ba
            color: #ffffff
        input[type="file"]::-webkit-file-upload-button
            cursor: pointer
            height: 100%
            position: absolute
            top: 0
            right: 0
            z-index: 99
            font-size: 50px
            opacity: 0
            -moz-opacity: 0
        input[type="file"]
            border: 0.680412px solid #FFFFFF
            border-radius: 13.6082px
            padding: 10px
            padding-right: 40px
            background: #000000ba
            color: #fff
            font-family: 'Space Grotesk'
            font-style: normal
            font-weight: 300
            font-size: 14px
            line-height: 18px
        .file-uploader
            max-width: 300px
            font-family: 'Space Grotesk'
            font-style: normal
            font-weight: 300
            font-size: 14px
            line-height: 18px
            i
                position: absolute
                right: 20px
                line-height: 1
                color: #4398FF
                top: 50%
                transform: translateY(-50%)

@media screen and (max-width: 992px)
    .project-progress
        .update-progres
            select
                width: 100%
