.pagination-container
  background: rgba(68, 68, 68, 0.25)
  border: 1px solid #2F2F2F
  border-radius: 25.93px
  margin: 16px 0px
  padding: 5px
  float: right
  width: fit-content
  .MuiPaginationItem-root
    color: white
  .MuiPaginationItem-root.Mui-selected
    background: #132944
    &:hover
      opacity: 0.8
      background: #132944