.new-homepage-container {
  .home-apply-btn {
    overflow: hidden;
    border: none;
    height: 34px;
    padding: 3px;
    min-width: 160px;
    border-radius: 30px;
    color: white;
    animation: shadow-fade 2s infinite;
    position: relative;
    background: black;
    font-size: 14px;

    span {
      position: relative;
      z-index: 1;
      display: block;
      width: 100%;
      padding: 0 16px;
      height: 100%;
      background-color: black;
      border-radius: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 0;
      margin: -100px;
      border-radius: inherit;
      background: conic-gradient(#000000, #000000, #44c0fe);
      -webkit-animation: rotate-border 4s linear infinite;
      -moz-animation: rotate-border 4s linear infinite;
      -o-animation: rotate-border 4s linear infinite;
      animation: rotate-border 4s linear infinite;
    }
  }

  .project-stats-card {
    border: 1px solid transparent;
    background:
      linear-gradient(#000000 0 0) padding-box,
      linear-gradient(to top, #000000, #494949) border-box;
  }

  .join-card-board {
    border: 1px solid transparent;
    background:
      linear-gradient(#000000 0 0) padding-box,
      linear-gradient(to top, #d4d4d4, #3f3f3f) border-box;
  }

  .move-up-down {
    animation: move-up-down 4s ease-in-out infinite;
  }

  .size-inc-desc {
    animation: size-inc-desc 4s ease-in-out infinite;
  }

  @keyframes shadow-fade {
    0% {
      box-shadow: 0px -8px 38.6px -3px #2479DF;
    }

    50% {
      box-shadow: 0px -8px 38.6px -3px #2478df99;
    }

    100% {
      box-shadow: 0px -8px 38.6px -3px #2479DF;
    }
  }

  @keyframes rotate-border {
    to {
      transform: rotate(360deg);
    }
  }

  @keyframes move-up-down {
    0% {
      top: -46px;
      left: 50px;
    }

    50% {
      top: -88px;
      left: 50px;
    }

    100% {
      top: -46px;
      left: 50px;
    }
  }

  @keyframes size-inc-desc {
    0% {
      scale: 0.8
    }

    50% {
      scale: 0.5
    }

    100% {
      scale: 0.8
    }
  }

  @keyframes gradient-flow {
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: 100% 100%;
    }
  }
  
  .animated-card-text {
    padding: 0;
    margin: 0;
    font-size: 28px;
    font-weight: bold;
    background: linear-gradient(90deg, #82d5ff 0%, #2479DE 100%);
    background-size: 400% 400%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    color: transparent;
    animation: gradient-flow 2s ease infinite;
  }

  @keyframes gradient-flow {
    0% {
      background-position: 0% 0%;
    }
    50% {
      background-position: 100% 100%;
    }
    100% {
      background-position: 0% 0%;
    }
  }

  .loop-animation {
    animation: spin 30s linear infinite;
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

}