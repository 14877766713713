.form-slider
    &.integreted-slider
        i
            color: white
            font-size: 25px
            font-weight: 300
        .CircularProgressbar-text
            text-decoration: underline
        .percentage-slider
            .CircularProgressbar
                width: 230px
        .icrement-icon
            img
                height: 58px
                width: 58px
                min-width: 58px
                min-height: 58px

@media screen and (max-width: 1200px)
    .form-slider
        &.integreted-slider
            .icrement-icon
                img
                    height: 40px
                    width: 40px
                    min-width: 40px
                    min-height: 40px
            .percentage-slider
                .CircularProgressbar
                    width: 200px

@media screen and (max-width: 767px)
    .form-slider
        &.integreted-slider
            .icrement-icon
                img
                    height: 30px
                    width: 30px
                    min-width: 30px
                    min-height: 30px
            .percentage-slider
                .CircularProgressbar
                    width: 120px
