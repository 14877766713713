@import "../../../../../../../variables.sass"

.all-mentor-section
    background: $card-item-background
    padding: 10px
    border-radius: 12px
    img
        border-radius: 21px
        object-fit: cover
        height: 175px
        width: 175px
        border-radius: 24px
    .mentor-name
        display: -webkit-box
        -webkit-line-clamp: 2
        -webkit-box-orient: vertical
        overflow: hidden
        text-overflow: ellipsis
        margin: 0.8rem 0 0.4rem 0
        height: 44px
        word-break: break-word
        font-weight: 500 !important
    .mentor-title
        display: -webkit-box
        -webkit-line-clamp: 2
        -webkit-box-orient: vertical
        overflow: hidden
        text-overflow: ellipsis
        height: 42px !important
        word-break: break-word
        font-size: 14px
    .update-score-action-item
        display: flex
        justify-content: center
        align-items: center
        position: relative
        .info-score-div
            position: absolute
            padding: 8px 12px
            border-radius: 12px
            right: -139px
            background: #303030
            color: white
            font-size: 13px
            line-height: 12px
            top: -28px
        img
            width: 14px
            height: 14px
            position: absolute
            right: 0
        .disable-score
            background: #303030 !important
            border: none !important

@media screen and (max-width: 1600px)
    .all-mentor-section
        img
            height: 125px
            width: 125px
@media screen and (max-width: 1200px)
    .all-mentor-section
        img
            height: 115px
            width: 115px
        .mentor-title
            height: 36px !important
        .mentor-name
            height: 38px
        .paragraph-new-medium
            font-size: 12px
@media screen and (max-width: 767px)
    .all-mentor-section
        margin-bottom: 0
        text-align: center
        .mentor-title
            min-height: 36px
        img
            margin: auto
            height: 109px
            width: 109px
            border-radius: 15px
