.vc-public-profile
    background: rgba(68, 68, 68, 0.25)
    border: 1px solid #2F2F2F
    border-radius: 18px
    padding: 30px 80px
    .vc-public-profile-section
        .vc-profile-img
            height: 128px
            width: 128px
            max-width: 128px
            max-height: 128px
            border-radius: 50%
            text-align: center
            display: flex
            justify-content: center
            align-items: center
            img
                height: 118px
                width: 118px
                max-width: 118px
                max-height: 118px
                border-radius: 50%
                object-fit: cover
        a
            &:hover
                color: #4398FF
        .vc-public-icons
            a
                color: #000
                text-decoration: none
                i
                    background: #4398FF
                    border-radius: 50%
                    padding: 5px
    .info-card
        min-height: 168.23px
        display: flex
        flex-direction: column
        justify-content: center

@media screen and (max-width: 1200px)
    .vc-public-profile
        .info-card
            min-height: 130px

@media screen and (max-width: 992px)
    .vc-public-profile
        padding: 20px 30px
        .vc-public-profile-section
            .vc-profile-img
                height: 97px
                width: 97px
                max-width: 97px
                max-height: 97px
                img
                    height: 90px
                    width: 90px
                    max-width: 90px
                    max-height: 90px

@media screen and (max-width: 767px)
    .vc-public-profile
        padding: 10px