.form-header-section
    .counter-card
        background: rgba(68, 68, 68, 0.25)
        border: 1px solid #2F2F2F
        border-radius: 20px
        text-align: center
        padding: 20px
        .counter-img
            height: 40px
            width: 40px
            object-fit: contain
            margin: auto
            margin-top: -45px
            margin-bottom: 15px
.form-section
    .projectDetails-top
        border: 1px solid #4398FF
        padding: 30px
        background: rgba(68, 68, 68, 0.25)
        border-radius: 25.93px
        .slick-slide
            .slide-content
                width: 95% !important
                margin: auto !important
                img, video
                    border-radius: 28.6875px
                    height: 300px
                    min-height: 300px
                    width: 100%
                    object-fit: cover
    .projectDetails
        padding: 0
        padding-bottom: 0
        p
            font-family: 'Space Grotesk'
            font-style: normal
            font-weight: 300
            font-size: 18px
            line-height: 38.81px
            color: #ffffff
        ul
            li
                line-height: 38.81px
                font-family: 'Space Grotesk'
                font-style: normal
                font-weight: 300
                font-size: 18px
                line-height: 120.5%
                color: #FFFFFF
        .css-10d9dml-MuiTabs-indicator, .css-7ozjwe
            background: transparent !important
        .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected, .css-1q2h7u5.Mui-selected
            background: #303030 !important
            border-radius: 15px 0px 0px 15px !important
            color: white !important
        .css-1h9z7r5-MuiButtonBase-root-MuiTab-root, .css-1q2h7u5
            margin: 30px 0 !important
            color: white !important
            text-align: left !important
            align-items: baseline !important
            min-height: 35px !important
        .css-35zqo5-MuiTabs-root, .css-1vr8b6v
            border-right: 2px solid !important
            border-color: #6B6B6B !important
            min-width: 330px !important
            height: 100% !important
        .css-lfwcke-MuiTabs-flexContainer, .css-j7qwjs
            margin-top: 30px !important
        .MuiTableCell-root
            border-bottom: none !important
            color: white !important
        .css-11xur9t-MuiPaper-root-MuiTableContainer-root, .css-13xy2my
            background: transparent !important
            box-shadow: none !important
        #vertical-tabpanel-0
        .MuiTabPanel-root
            width: 100%
        .tabpanel
            width: 100%
    .founders-form

        .success
            color: #65DB6A !important
        .error
            color: red !important
        input
            background: transparent
            border-radius: 0
            border: none
            border-bottom: 1px solid #fff
            font-family: 'Space Grotesk'
            font-style: normal
            font-weight: 300
            font-size: 18px
            line-height: 104.5%
            padding: 10px
            color: #FFFFFF
            &::placeholder
                color: grey
        .form-control
            &:focus
                box-shadow: none !important
        input[type="date"]
            position: relative
            overflow: hidden
        input[type="date"]::-webkit-calendar-picker-indicator
            background: transparent
            bottom: 0
            color: transparent
            cursor: pointer
            height: auto
            left: 0
            position: absolute
            right: 0
            top: 0
            width: auto
        input[type="file"]::-webkit-file-upload-button
            cursor: pointer
            height: 100%
            position: absolute
            top: 0
            right: 0
            z-index: 99
            font-size: 50px
            opacity: 0
            -moz-opacity: 0
        input[type="file"]
            border: 1px solid #FFFFFF
            border-radius: 20px
            padding: 10px
        .file-uploader
            max-width: 300px
            i
                position: absolute
                right: 20px
                line-height: 1
                color: #4398FF
                top: 50%
                transform: translateY(-50%)
        .malti-select
            .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select
                padding: 10px
            .css-6od3lo-MuiChip-label, .css-9iedg7
                font-family: 'Space Grotesk'
                font-style: normal
                font-weight: 300
                font-size: 16px
                line-height: 104.5%
                color: #FFFFFF
            .css-1q79v3g-MuiButtonBase-root-MuiChip-root, .css-kvb41a
                background: #ffffff14
                .MuiChip-deleteIcon
                    color: #ffffffa1
            .css-1d3z3hw-MuiOutlinedInput-notchedOutline, .css-igs3ac
                border: none
                border-bottom: 1px solid #fff
                border-radius: 0
            .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon, .css-1636szt
                color: white
        .select-option
            -webkit-appearance: none
            -moz-appearance: none
            -ms-appearance: none
            appearance: none
            outline: 0
            box-shadow: none
            border: 0!important
            background-color: transparent
            flex: 1
            padding: 15px
            cursor: pointer
            font-family: 'Space Grotesk'
            font-style: normal
            font-weight: 300
            font-size: 16px
            line-height: 104.5%
            color: #FFFFFF
            border-bottom: 1px solid #fff !important
            border-radius: 0
            filter: brightness(20.5) !important
            option
                background: #000
                color: #fff
                &:hover
                    background: #fff !important
                    color: #000 !important
            &::after
                content: '\25BC'
                position: absolute
                top: 0
                right: 0
                padding: 0 1em
                background: #2b2e2e
                cursor: pointer
                pointer-events: none
                transition: .25s all ease

.form-section .projectDetails .css-1h9z7r5-MuiButtonBase-root-MuiTab-root
    &::after
        content: ""
        position: absolute
        right: 20px
        top: 50%
        background: #ffffff !important
        height: 20px
        width: 20px
        border-radius: 50%
        transform: translateY(-50%)
    &.defaultTab
        color: #ffffff !important
.form-section .projectDetails .css-1h9z7r5-MuiButtonBase-root-MuiTab-root
    &.successTab
        color: #65DB6A !important
        &::after
            background: #65DB6A !important
.form-section .projectDetails .css-1h9z7r5-MuiButtonBase-root-MuiTab-root
    &.warningTab
        color: #FFB800 !important
        &::after
            background: #FFB800 !important

@media screen and (max-width: 1200px)
    .form-header-section
        .form-hrading
            display: block !important
            div
                justify-content: center
                margin-bottom: 10px
    .form-section
        .projectDetails
            .css-35zqo5-MuiTabs-root, .css-1vr8b6v
                min-width: 280px !important

@media screen and (max-width: 992px)
    .form-header-section
        .form-hrading
            display: block !important
            div
                justify-content: center
                margin-bottom: 10px
    .form-section
        .projectDetails-top
            padding: 15px
            .projectDetails
                .css-35zqo5-MuiTabs-root, .css-1vr8b6v
                    min-width: 215px !important
                .css-1h9z7r5-MuiButtonBase-root-MuiTab-root, .css-1q2h7u5
                    font-size: 11px
@media screen and (max-width: 767px)
    .form-header-section
        .form-hrading
            div
                &:nth-child(1)
                    margin-bottom: 20px
                    .accelerate-img
                        height: 14px
                    .form-text
                        font-size: 18.2892px
        .counter-card
            margin-bottom: 20px
            height: auto
            padding: 8px
            padding: 15px
            .counter-img
                height: 26px
                width: 26px
                margin-top: -20px
    .form-section
        .projectDetails-top
            padding: 7px
            .projectDetails
                .css-35zqo5-MuiTabs-root, .css-1vr8b6v
                    min-width: 100px !important
                .css-1h9z7r5-MuiButtonBase-root-MuiTab-root, .css-1q2h7u5
                    font-size: 9px
                    &::after
                        right: 3px
                        height: 10px
                        width: 10px
                .form-control
                    font-size: 13px
        .founders-form
            .select-option
                font-size: 10px
            .malti-select
                .css-1q79v3g-MuiButtonBase-root-MuiChip-root, .css-la0a2z
                    max-width: 66%
                    height: 26px
                    span, .css-9iedg7
                        font-size: 11px
                        color: #fff
                        overflow: hidden
                        max-width: 130px
                    .MuiChip-deleteIcon
                        color: #fff
    .accelerate-program-section
        h5
            text-align: center
            width: 70%
            margin: auto
            margin-bottom: 20px
