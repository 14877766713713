@import "../../../../../../variables.sass";

.mentor-score-modal {
  color: white;

  &.active-loader {
    .modal-body {
      background-color: #35353545 !important;
      z-index: 10
    }
  }

  .score-modal-container {
    min-height: 200px;
    max-width: 450px;
    margin: auto;
    padding: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
    position: relative;

    .modal-loader {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 10;
      background: #17171773;
    }

    .header {
      text-align: center;
      font-family: 'Space Grotesk'
    }

    .reward-form {
      .reward-form-item {
        margin: 30px 0px;
        display: grid;

        label {
          margin-top: 20px;
          margin-bottom: 10px;
          font-family: 'Space Grotesk'
        }

        input {
          border: none;
          outline: none;
          background: transparent;
          border-bottom: 1px solid $text-primary-color;
          max-width: 200px;
          font-size: 18px;
          color: $text-primary-color;
        }

        span {
          font-family: 'Space Grotesk';
          font-size: 12px;
          color: grey;
        }

        .score-slider {
          margin-top: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 20px;
          margin-right: 10px;

          .score-digits {
            background-color: $chip-bg-color;
            border-radius: 10px;

            h4 {
              min-width: 70px;
              text-align: center;
              font-family: 'Space Grotesk';
              margin: 0px;
              padding: 10px;
              font-size: 26px;
            }
          }
        }
      }

      .progress-bar-container {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: auto;
        position: relative;

        .score-modal-calculation-info {
          border-radius: 18px;
          border: 2px solid #000;
          background: #161616;
          padding: 15px;
          width: 228px;
          font-size: 12px;
          text-align: center;
          position: absolute;
          right: 0px;
          top: -146px;

          p {
            margin: 0px;
          }
        }

        .progress-bar-item {
          width: 90px;
        }

        .progress-text {
          display: flex;
          align-items: start;

          .text {
            margin-left: 20px;
            font-size: 24px;
            font-family: 'Space Grotesk';
            font-weight: 200;
          }

          .calculation-info-icon {
            cursor: pointer;
          }
        }
      }
    }

    .action-button {
      text-align: center;
      margin-top: 30px;
    }
  }
}