.feature-vc-section
.latest-bets-section
    .table
        th
            color: #fff
            border-bottom: none
        td
            color: #fff
            border-bottom: none
        .table-data
            tr
                background: #1F1F1F
.explore-section
    .form-check-input
        background-color: transparent
        border: 1px solid #fff
        border-radius: 50%
        cursor: pointer
        &:checked[type=radio]
            background-image: none
            background: #fff
            border: 3px solid #65DB6A
        &:focus
            box-shadow: none