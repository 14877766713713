.just-in
    display: flex
    align-items: center
    gap: 40px
    h1
        font-family: 'Space Grotesk'
        font-style: normal
        font-weight: 700
        font-size: 72.3784px
        line-height: 104.5%
        background: linear-gradient(180deg, #4398FF 0%, #2378DE 100%)
        -webkit-background-clip: text
        -webkit-text-fill-color: transparent
        background-clip: text
        text-fill-color: transparent
    .table-filter-content
        margin-top: 0
.vc-slider-card
    .vc-card
        width: 95%
        overflow: hidden
.gaming-scroll
    max-height: 1050px
    overflow-y: scroll
    overflow-x: hidden
.projects-scroll
    max-height: 2000px
    overflow-y: scroll
    overflow-x: hidden

.all-project-slider
    padding-bottom: 30px
    .slick-slider
        .slick-prev
            bottom: -35px
            top: auto
            left: 47%
            transform: translateX(-50%)
            background: linear-gradient(180deg, #4398FF 0%, #2378DE 100%)
            border-radius: 50%
            display: flex
            align-items: center
            justify-content: center
            height: 24px
            width: 24px
            &::before
                font-size: 18px
                content: "\ea64"
                font-family: remixicon!important
                font-style: normal
                -webkit-font-smoothing: antialiased
                color: #000000
        .slick-next
            bottom: -35px
            top: auto
            right: 47%
            transform: translateX(-50%)
            background: linear-gradient(180deg, #4398FF 0%, #2378DE 100%)
            border-radius: 50%
            display: flex
            align-items: center
            justify-content: center
            height: 24px
            width: 24px
            &::before
                font-size: 18px
                content: "\ea6e"
                font-family: remixicon!important
                font-style: normal
                -webkit-font-smoothing: antialiased
                color: #000000

.all-project-list-tab
    .table-filter-content
        margin-top: 20px

@media screen and (max-width: 1400px)
    .all-project-slider
        .slick-slider
            .slick-prev
                left: 45%
            .slick-next
                right: 45%

@media screen and (max-width: 992px)
    .all-project-list-tab
        .table-filter-content
            margin-top: 10px

@media screen and (max-width: 767px)
    .all-project-slider
        padding-bottom: 0
    .rocket-all-project
        height: 26px
