@import "../../../../../../../variables.sass"

.list-table-section
    background: $card-background
    // border: 1px solid #2F2F2F
    border-radius: 18px
    padding: 40px 70px
    .vc-name
        height: 48px
        display: -webkit-box
        -webkit-line-clamp: 2
        -webkit-box-orient: vertical
        overflow: hidden
        text-overflow: ellipsis
    .vc-areas
        height: 44px
        display: -webkit-box
        -webkit-line-clamp: 2
        -webkit-box-orient: vertical
        overflow: hidden
        text-overflow: ellipsis
        padding: 0 10px
    &.scrolled
        height: 1300px
        overflow-y: scroll
        overflow-x: hidden
    .vc-list-card
        background: $card-item-background
        border-radius: 17px
        padding: 20px 0
        img
            border: 1px solid #fff
            border-radius: 50%
            padding: 5px
            object-fit: cover
            height: 90px
            width: 90px
            min-height: 90px
            min-width: 90px
        .social-link-table
            display: flex
            align-items: center
            justify-content: center
            gap: 20px
            padding-left: 0
            margin-bottom: 0
            li
                list-style: none
                a
                    width: 24px
                    height: 24px
                    color: black
                    background: $text-primary-color
                    display: flex
                    align-items: center
                    justify-content: center
                    text-decoration: none
                    border-radius: 50%
                    i
                        margin-top: 2px
        .request-to-onnect-btn
            background: #44444440 !important
            border: 1px solid #4398FF !important
            width: auto !important
            &.green-border
                background: $text-primary-color !important
                // border: 0.583333px solid #2F2F2F !important
                border-radius: 7.5px !important
            &.yellow-border
                background: rgba(68, 68, 68, 0.25) !important
                border: 0.583333px solid #FFB800 !important
                border-radius: 7.5px !important
                -webkit-text-fill-color: #FFB800 !important
            &.red-border
                background: rgba(68, 68, 68, 0.25)
                border: 1px solid #FF0000 !important
                border-radius: 7.5px !important
                -webkit-text-fill-color: #FF0000 !important
            &.request-btn
                &:hover
                    background: $card-item-hover-background !important

@media screen and (max-width: 1400px)
    .list-table-section
        padding: 30px

@media screen and (max-width: 1200px)
    .list-table-section
        padding: 30px 35px
        .vc-areas
            height: 40px
        .vc-list-card
            .request-to-onnect-btn
                padding: 10px !important

@media screen and (max-width: 992px)
    .list-table-section
        .vc-list-card
            img
                height: 70px
                width: 70px
                min-height: 70px
                min-width: 70px

@media screen and (max-width: 767px)
    .list-table-section
        padding: 10px 5px
        border-radius: 12.0162px
        .vc-areas
            height: 40px
        .vc-list-card
            border-radius: 12.088px
            .request-to-onnect-btn
                padding: 10px !important
                font-size: 10px !important
            .social-link-table
                li
                    a
                        width: 17px
                        height: 17px
                        min-width: 17px
                        min-height: 17px
                        font-size: 13px
@media screen and (max-width: 390px)
    .list-table-section
        padding: 10px
        .vc-list-card
            .request-to-onnect-btn
                padding: 8px !important
                font-size: 8px !important
            .social-link-table
                gap: 15px
