.info-card
    background: #0A1F38
    border: 1px solid #2F2F2F
    border-radius: 18px
    padding: 20px 35px
    h2
        background: linear-gradient(90deg, #4398FF 0%, #2479DF 58.11%)
        -webkit-background-clip: text
        -webkit-text-fill-color: transparent
        background-clip: text
        text-fill-color: transparent

// @media screen and (max-width: 1600px)
//     .info-card 
//         h2

@media screen and (max-width: 767px)
    .info-card
        border-radius: 11.7465px
        padding: 13px 30px
