.blog-card-lg-container {
  max-width: 600px;
  width: 100%;
  min-height: 200px;
  background-color: #f4f4f4;
  border-radius: 16px;
  padding: 3%;
  margin: auto;
  cursor: pointer;

  .blog-image {
    img {
      aspect-ratio: 16/6;
      object-fit: cover;
      border-radius: 8px;
      width: 100%;
    }
  }

  .blog-title {
    font-family: 'Space Grotesk';
    font-size: 1.6rem;
    margin-top: 20px;
    min-height: 62px;
    margin-bottom: 20px;
    font-weight: 600;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .blog-short-desc {
    font-family: 'Space Grotesk';
    font-weight: 400;
    margin-top: 10px;
    margin-bottom: 10px;
    overflow: hidden;
    min-height: 48px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .publication-share {
    display: flex;
    align-items: center;
    justify-content: end;

    .date-and-share {
      display: flex;
      align-items: center;

      span {
        font-size: 0.9rem;
        margin-right: 12px;
        font-weight: 500;
      }

      i {
        font-size: 1.2rem;
        cursor: pointer;
      }
    }
  }
}

.blog-card-sm-container {
  width: 100%;
  background-color: #f4f4f4;
  border-radius: 16px;
  padding: 3%;
  cursor: pointer;

  .blog-image {
    img {
      aspect-ratio: 16/6;
      object-fit: cover;
      width: 100%;
      border-radius: 8px;
      object-fit: cover;
    }
  }

  .blog-title {
    font-size: 1.5rem;
    font-weight: 500;
    font-family: "Space Grotesk";
    margin-top: 16px;
    margin-bottom: 16px;
    min-height: 58px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .publication-share {
    display: flex;
    align-items: center;
    justify-content: end;

    .date-and-share {
      display: flex;
      align-items: center;

      span {
        font-size: 0.9rem;
        margin-right: 12px;
        font-weight: 500;
      }

      i {
        font-size: 1.2rem;
        cursor: pointer;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .blog-card-lg-container {

    .blog-title {
      font-size: 1.2rem !important;
      font-weight: 700;
      min-height: auto !important;
      margin-bottom: 10px;
      display: block;
    }

    .blog-short-desc {
      font-size: 0.9rem;
      margin-bottom: 10px !important;
    }

    .publication-share {
      justify-content: end;

      button {
        display: none;
      }
    }
  }

  .blog-card-sm-container {

    .blog-title {
      font-size: 1.2rem !important;
      font-weight: 700;
      min-height: auto !important;
      display: block !important;
    }

    .publication-share {
      justify-content: end;

      button {
        display: none;
      }
    }
  }
}