@import '../../../../variables.sass'

.table-filter-content
    margin-top: 35px
    .heading-table
        display: flex
        align-items: center
        justify-content: flex-end
        gap: 15px
        // .dropdown
        //     -webkit-appearance: none
        //     -moz-appearance: none
        //     background: transparent
        //     background-image: url("data:image/svg+xml;utf8,<svg fill='cornflowerblue' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>")
        //     background-repeat: no-repeat
        //     background-position-x: 97%
        //     background-position-y: 7px
        //     padding: 10px 15px
        //     background-color: #1A1A1A
        //     border: 1px solid #2F2F2F
        //     border-radius: 10px
        //     position: relative
        //     font-family: 'Space Grotesk'
        //     font-style: normal
        //     font-weight: 300
        //     font-size: 14px
        //     line-height: 18px
        //     color: #FFFFFF
        //     width: 165px
        //     .dropdown-menu
        //         left: 0 !important
        //         background: black
        //         li
        //             color: white
        //             padding: 5px 10px
        //             font-family: 'Space Grotesk'
        //             font-style: normal
        //             font-weight: 300
        //             font-size: 14px
        //             line-height: 18px
        //     .dropdown-toggle
        //         font-family: 'Space Grotesk'
        //         font-style: normal
        //         font-weight: 300
        //         font-size: 14px
        //         color: #FFFFFF !important
        //         line-height: 18px

.request-to-onnect-btn
    font-family: 'Space Grotesk' !important
    font-style: normal !important
    font-weight: 300 !important
    font-size: 12px !important
    line-height: 104.5% !important
    text-align: center !important
    color: #FFFFFF !important
    background: rgba(68, 68, 68, 0.25)
    border: 0.583333px solid #2F2F2F
    border-radius: 7.5px !important
    -webkit-background-clip: unset !important
    -webkit-text-fill-color: white !important
    padding: 10px 15px !important
    width: 100%
    &.green-border
        background: $text-primary-color !important
        border: 0.583333px solid #2F2F2F !important
        border-radius: 7.5px !important
    &.yellow-border
        background: transparent !important
        border: 0.583333px solid #FFB800 !important
        border-radius: 7.5px !important
        -webkit-text-fill-color: #FFB800 !important
    &.red-border
        background: transparent !important
        border: 1px solid #FF0000 !important
        border-radius: 7.5px !important
        -webkit-text-fill-color: #FF0000 !important

.partner-list-data
    margin-top: 20px
    padding: 35px 40px
    padding-right: 20px
    background: $card-background
    // border: 1px solid #2F2F2F
    border-radius: 25.93px
    // height: 500px
    .css-41abqd-MuiTableContainer-root, .css-1evjoiv
        max-height: 440px
        position: relative
        padding-right: 20px
        // min-height: 150px
    .no-project-found
        width: 100%
        min-height: 50vh
        display: flex
        justify-content: center
        align-items: center
        font-size: 26px
    table
        border: none
        min-height: 140px
        thead
            th
                padding: 15px
                color: $text-primary-color !important
                font-family: 'Space Grotesk'
                font-style: normal
                font-weight: 300
                font-size: 16px
                line-height: 20px
                background: $card-background
                border-bottom: 0 !important
            th:first-child
                // border-radius: 20px 0 0 20px
                padding-left: 40px
            th:last-child
                border-radius: 0 20px 20px 0
        tbody
            min-height: 300px !important
            max-height: 600px
            overflow: auto
            tr
                td, .css-1ex1afd-MuiTableCell-root
                    padding: 18px
                    padding-left: 0
                    color: #fff
                    font-family: 'Space Grotesk'
                    font-style: normal
                    font-weight: 300
                    font-size: 16px
                    line-height: 20px
                    border-bottom: 0 !important
                    button, .claimBtn
                        padding: 7px 22px
                        color: #FFF
                        font-family: 'Space Grotesk'
                        font-style: normal
                        font-weight: 300
                        font-size: 16px
                        line-height: 100%
                        outline: none
                        background: linear-gradient(180deg, #4398FF 0%, #2479DF 100%)
                        -webkit-background-clip: text
                        -webkit-text-fill-color: transparent
                        background-clip: text
                        text-fill-color: transparent
                        background-color: rgba(68, 68, 68, 0.25)
                        border: 1px solid #2F2F2F
                        border-radius: 9.24378px
                    .claimBtn:disabled,
                    .claimBtn[disabled]
                        border: 1px solid #999999
                        background-color: #d3d3d3
                        color: #575757
                .social-icons-link
                    ul
                        padding-left: 0
                        margin-bottom: 0
                        display: flex
                        align-items: center
                        gap: 10px
                        justify-content: center
                        li
                            list-style: none
                            a
                                height: 21px
                                width: 21px
                                min-width: 21px
                                min-height: 21px
                                background: #4398FF
                                color: black
                                border-radius: 50%
                                text-decoration: none
                                font-size: 12px
                                display: flex
                                align-items: center
                                justify-content: center
    .css-1fw5qro-MuiPaper-root, .css-1cf86vr
        background-color: transparent !important
        box-shadow: none !important
    .social-link-table
        display: flex
        align-items: center
        // padding-left: 20px !important
        gap: 10px
        padding-left: 0
        margin-bottom: 0
        li
            list-style: none
            a
                width: 25px
                height: 25px
                // min-width: 21px
                // min-height: 21px
                color: black
                background: $text-primary-color !important
                display: flex
                align-items: center
                justify-content: center
                text-decoration: none
                border-radius: 50%
    .table-description
        display: -webkit-box
        -webkit-line-clamp: 3
        -webkit-box-orient: vertical
        overflow: hidden
        text-overflow: ellipsis
        position: relative
        .view-more-action
            position: absolute
            bottom: 0
            right: 0
            background: $card-background
            padding: 0px 12px
            color: $text-primary-color
            text-decoration: underline
            font-weight: 500
            cursor: pointer
.no-data-p
    position: absolute
    left: 50%
    top: 50%
    transform: translate(-50%, 50%)
    margin-top: 10px
@media screen and (max-width: 1200px)
    .request-to-onnect-btn
        padding: 8px 13px !important
    .partner-list-data
        padding: 25px 30px
        margin-top: 20px
@media screen and (max-width: 992px)
    .partner-list-data
        margin-top: 15px
        padding: 30px
        table
            thead
                th
                    font-size: 14px
                    line-height: 20px

    .table-filter-content
        .heading-table

@media screen and (max-width: 767px)
    .partner-list-data
        border-radius: 15px
        padding: 15px
        table
            thead
                th
                    padding: 10px
                    font-weight: 300
                    font-size: 10px
                    line-height: 10px
            tbody
                tr
                    td, .css-1ex1afd-MuiTableCell-root
                        padding: 9px
                        font-size: 10px
                        line-height: 10px
    .css-1wlk0hk-MuiAvatar-root
        width: 30px !important
        height: 30px !important
    .table-filter-content
        .heading-table
            justify-content: center
