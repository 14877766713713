.dashboard-profile-section
    z-index: 2
    position: relative
    .dashboard-title
        height: 35px
    .profile-banner
        position: relative
        height: 350px
        width: 100%
        margin-bottom: 10px
        background: #4397fe2a
        // &::after
        //     content: ""
        //     top: 0
        //     left: 0
        //     position: absolute
        //     width: 50%
        //     height: 100%
        //     background: linear-gradient(89.89deg, #4398fe42 19.17%, rgba(217, 217, 217, 0) 94.12%)
        img
            width: 100%
            height: 100%
            min-width: 100%
            min-height: 100%
            object-fit: cover
    .profil-text
        // padding: 2% 8%
        // background: black
        // margin: 0px
        // max-width: 100%
        .profil-text-section
            display: flex
            justify-content: space-between
            align-items: flex-end
            margin-top: -100px
            .user-profile
                border-radius: 50%
                width: 180px
                height: 180px
                overflow: hidden
                background: #193d67
                img
                    width: 100%
                    height: 100%
                    min-width: 100%
                    min-height: 100%
                    object-fit: cover
            .deshboard-social
                gap: 10px
                a
                    font-size: 20px
                    color: #000
                    background: #2479DF
                    border-radius: 50%
                    height: 35px
                    width: 35px
                    min-width: 35px
                    min-height: 35px
                    display: flex
                    align-items: center
                    justify-content: center
                    text-decoration: none

            .duelist-king
                font-family: 'Space Grotesk'
                font-style: normal
                font-weight: 300
                font-size: 38.8571px
                line-height: 50px
                color: #FFFFFF
.profile-section
    .back-btn
        p
            text-decoration: underline
    .public-btn
        button
            i
                color: #4398FF
.form-slider
    .percentage-slider
        .CircularProgressbar
            width: 70px
@media screen and (max-width: 1400px)
    .dashboard-profile-section
        .profile-banner
                height: 300px
@media screen and (max-width: 1200px)
    .dashboard-profile-section
        .profile-banner
                height: 250px
        .profil-text
            .profil-text-section
                margin-top: -90px
                .user-profile
                    width: 175px
                    height: 175px
                    max-height: 175px
                    max-width: 175px
                .deshboard-social
                    a
                        height: 30px
                        width: 30px
                        min-width: 30px
                        min-height: 30px
                        font-size: 18px
@media screen and (max-width: 992px)
    .dashboard-profile-section
        .profil-text
            .profil-text-section
                margin-top: -75px !important
                .user-profile
                    width: 125px
                    height: 125px
                    max-height: 125px
                    max-width: 125px
                .deshboard-social
                    a
                        height: 25px
                        width: 25px
                        min-width: 25px
                        min-height: 25px
                        font-size: 14px
                .duelist-king
                    font-size: 20.8571px
                    line-height: 32px
    .form-slider
        .percentage-slider
            .CircularProgressbar
                width: 50px
@media screen and (max-width: 767px)
    .dashboard-profile-section
        .profile-banner
                height: 160px
        .dashboard-title
            margin: 10px 0
        .profil-text
            .profil-text-section
                display: block !important
                margin-top: -36px !important
                .deshboard-social
                    justify-content: end
                .user-profile
                    width: 80px
                    height: 80px
                .new-primary-button-small
                    font-size: 10px
                    padding: 4px 8px !important
                .profile-btn
                    justify-content: space-between
                .deshboard-social
                    i
                        font-size: 14px !important
                        padding: 4px
    .form-slider
        .percentage-slider
            .CircularProgressbar
                width: 35px
