.login-page
    display: flex
    align-items: center
    justify-content: center
    height: 100vh
    overflow-y: scroll

.animation-part
    border-radius: 42.8155px
    background: linear-gradient(310deg, #4398FF 30.29%, #2479DF 60.02%, #000000 84.65%)
    padding: 2px
    --borderWidth: 3px
    position: relative
    z-index: 1
    overflow: hidden
    &::after
        content: ""
        position: absolute
        left: 0
        top: 0
        z-index: -1
        top: calc(-1 * var(--borderWidth))
        left: calc(-1 * var(--borderWidth))
        height: calc(100% + var(--borderWidth) * 2)
        width: calc(100% + var(--borderWidth) * 2)
        background: linear-gradient(320.73deg, #4398FF 30.29%, #2479DF 60.02%, #000000 84.65%)
        border-radius: calc(2 * var(--borderWidth))
        z-index: -1
        animation: animatedgradient 2s ease alternate infinite
        background-size: 300% 300%
.login-form
    background: #000000
    border-radius: 43px
    text-align: center
    z-index: 1
    position: relative
    max-width: 100%
    padding: 30px
    .sign-up-redirect
        border: 2px solid #4398FF
        // width: max-content
        margin: auto
        margin-top: 20px
        padding: 10px 20px
        border-radius: 24px
        p
            color: white
            margin: 0
            cursor: pointer
            span
                font-weight: 600
    .sign-up-container
        display: contents
        .join-symbiote
            color: #54C4FC
            font-weight: 600
            display: block
            margin-bottom: 8px
        .fill-form
            color: white
            font-weight: 400
        .new-color-button
            min-width: 200px
            font-size: 14px
        .form-selection-container
            background: #141414
            border-radius: 16px
            margin-top: 30px
            .what-are-you
                color: white
                padding: 11px 0px
                display: block
            .radio-selection-item
                padding: 10px 0px
                display: flex
                width: 100%
                justify-content: center
                align-items: center
                .radio-selection
                    display: grid
                    padding: 0px 12px
                    label
                        color: white
                        font-weight: 500
                        font-size: larger

    .css-1aquho2-MuiTabs-indicator, .css-ttwr4n
        height: 9px
        background: linear-gradient(180deg, #4398FF 0%, #2479DF 100%)
        border-radius: 5px
    .login-tab-container
        button
            color: #FFF !important
            text-align: center
            font-size: 18px
            font-family: Space Grotesk
            font-style: normal
            font-weight: 400
            line-height: normal
    .css-heg063-MuiTabs-flexContainer, .css-k008qs
        margin-bottom: 4px
        border-bottom: 1px solid #6D6D6D
        justify-content: space-around
    .css-q8hpuo-MuiFormControl-root
        border: 1px solid white
    .MuiFormControl-root, .css-1nrlq1o-MuiFormControl-root, .css-13sljp9
        width: 80%
        margin: 10px auto
    .css-1d3z3hw-MuiOutlinedInput-notchedOutline, .css-igs3ac
        border-color: white
        border-radius: 20px
    .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input, .css-1x5jdmq
        color: #fff
        padding: 12px 14px
    .paragraph-new
        color: #FFF !important
        font-size: 16px
        font-family: Space Grotesk
        font-style: normal
        font-weight: 500
        line-height: 104.5%
        margin-bottom: 9px
    .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root, .css-1bp1ao6
        border-radius: 20px
    .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root, .css-1bp1ao6
        &:hover
            .MuiOutlinedInput-notchedOutline
                border-color: #fff
    .password-visible
        position: absolute
        right: -30px
        top: 28%
        transform: translateY(50%)
        color: white
        font-size: 20px
.login-model
    border-radius: 43px
    .modal-content
        background: transparent !important
.success-form-data
    background: linear-gradient(180deg, #4398FF 0%, #2479DF 100%)
    -webkit-background-clip: text
    -webkit-text-fill-color: transparent
    background-clip: text
    text-fill-color: transparent
@media screen and (max-width: 767px)
    .modal-content
        width: 96% !important
    .login-form
        padding: 6% 3%
        .sign-up-redirect
            p
                font-size: 12px
        .sign-up-container
            .form-selection-container
                .radio-selection-item
                    .radio-selection
                        label
                            font-size: 12px
    .login-tab-container
        button
            min-width: 50px
            padding: 0px
            font-size: 14px !important
