.mint-nft-container {
  color: white;
  margin-top: 1rem;
  position: relative;
  min-height: 200px;

  .mint-nft-loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #0000009e;
  }

  .container-heading {
    font-family: 'Space Grotesk';
    font-style: normal;
    font-weight: 700;
    font-size: 3.5rem;
    text-align: center;
  }

  .nft-card-image {
    text-align: center;
    max-width: 600px;
    margin: auto;
    margin-top: 2rem;

    img {
      width: 100%;

      &.inactive {
        opacity: 0.3;
      }
    }
  }

  .nft-card-item {
    max-width: 400px;
    max-height: 600px;
    overflow: hidden;
    text-align: center;
    margin: auto;
    margin-top: 2rem;

    img {
      width: 100%;
    }

    video {
      width: 100%;
    }
  }

  .page-actions {
    text-align: center;
    margin-top: 2rem;

    .non-eligible-text {
      font-size: 1.2rem;
    }
  }

}

@media screen and (max-width: 550px) {
  .mint-nft-container {
    .container-heading {
      font-size: 2rem;
    }

    .nft-card-image {
      margin-top: 1rem;
    }

    .page-actions {
      .non-eligible-text {
        font-size: 1rem;
      }
    }
  }
}