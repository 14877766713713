@import "../../../../../variables.sass"

.dashboard-section
    // max-height: 1600px
    // overflow-y: scroll
    // overflow-x: hidden
    .symbiotescore-section
        background: $card-background
        // border: 1px solid #2F2F2F
        border-radius: 18px
        padding: 20px 10px 20px 50px
        .percentage-slider
            .CircularProgressbar
                width: 90px
                margin: 0 5px 0 20px
                .CircularProgressbar-text
                    font-size: 35px
        .symbiotescore-text
            h3
                font-family: 'Space Grotesk'
                font-style: normal
                font-weight: 700
                font-size: 36.1527px
                line-height: 104.5%
                color: #FFFFFF
    .counter-section
        .counters-card
            background: $card-background
            // border: 1px solid #2F2F2F
            border-radius: 18px
            padding: 25px 35px
            // h2
            //     font-family: 'Space Grotesk'
            //     font-style: normal
            //     text-transform: uppercase
            //     background: linear-gradient(90.03deg, #4398FF -5.59%, #2479DF 65.86%)
            //     -webkit-background-clip: text
            //     -webkit-text-fill-color: transparent
            //     background-clip: text
            //     text-fill-color: transparent
    .courses-section
        padding-bottom: 50px
        .media-slider
            // iframe
            //     width: 100%
            //     max-height: 250px
            //     border-radius: 29px
            .slick-slider
                .slick-track
                    margin-left: inherit
            .slick-arrow
                display: block
                bottom: -50px
                top: auto
                &.slick-prev
                    left: 46%
                    z-index: 1
                    &::before
                        color: #4398FF
                        font-size: 25px
                        // opacity: 1
                &.slick-next
                    right: 46%
                    z-index: 1
                    &::before
                        color: #4398FF
                        font-size: 25px
                        // opacity: 1
            .slick-slide
                .slide-content
                    width: 95% !important
                    margin: auto !important
                    .content
                        img, video
                            border-radius: 28.6875px
                            height: 300px
                            min-height: 300px
                            width: 100%
                            object-fit: cover
                    .slider-card-content
                        p
                            width: 74%
                            margin: auto

    .event-section
        .event-bg
            background: $card-background
            // border: 1px solid #2F2F2F
            border-radius: 18px
            padding: 25px
            height: 93%
            .event-card
                background: $card-item-background
                border-radius: 21px
                padding: 20px
            .event-card2
                background: $card-item-background
                border-radius: 21px
                padding: 20px
                margin-bottom: 20px
                &:last-child
                    margin-bottom: 0
.partner-score-80
    background: linear-gradient(90.03deg, #4398FF -5.59%, #2479DF 65.86%)
    -webkit-background-clip: text
    -webkit-text-fill-color: transparent
    background-clip: text
    text-fill-color: transparent
@media screen and (max-width: 1750px)
    .dashboard-section
        .counter-section
            .counters-card
                padding: 25px

@media screen and (max-width: 1600px)
    // .dashboard-section
    //     .mentors-section
    //         .connection-section
    //             .all-mentor-section
    //                 p
    //                     display: -webkit-box
    //                     -webkit-line-clamp: 2
    //                     -webkit-box-orient: vertical
    //                     overflow: hidden
    //                     min-height: 44px
    //                     text-overflow: ellipsis
@media screen and (max-width: 1400px)
    .dashboard-section
        .courses-section
            .media-slider
                .slick-slide
                    .slide-content
                        .content
                            img, video
                                min-height: 250px

@media screen and (max-width: 1200px)
    .dashboard-section
        .courses-section
            .media-slider
                .slick-arrow.slick-next
                    right: 45%
@media screen and (max-width: 992px)
    .dashboard-section
        .counter-section
            .counters-card
                padding: 15px 20px
        .symbiotescore-section
            padding: 20px 10px
        .courses-section
            .media-slider
                .slick-arrow.slick-next
                    right: 40%
@media screen and (max-width: 767px)
    .mentors-section
        .connection-section
            .accelerate-list-slider
                padding: 0 30px
                .slick-prev, .slick-next
                    z-index: 11
                .slick-prev
                    border: 1px solid #fff
                    border-radius: 50%
                    display: flex
                    align-items: center
                    justify-content: center
                    height: 20px
                    width: 20px
                    &::before
                        font-size: 12px
                        content: "\ea64"
                        font-family: remixicon!important
                        font-style: normal
                        -webkit-font-smoothing: antialiased
                .slick-next
                    display: flex
                    align-items: center
                    justify-content: center
                    height: 20px
                    border: 1px solid #fff
                    border-radius: 50%
                    width: 20px
                    &::before
                        font-size: 12px
                        content: "\ea6e"
                        font-family: remixicon!important
                        font-style: normal
                        -webkit-font-smoothing: antialiased
    .dashboard-section
        .symbiotescore-section
            .percentage-slider
                .CircularProgressbar
                    margin: 0
                    width: 55px
            .symbiotescore-text
                h3
                    font-size: 18.1527px
                p
                    font-size: 8px
                    margin-bottom: 0
                    margin-top: 5px
        .counter-section
            .counters-card
                padding: 12px
                border: 0.496855px solid #2F2F2F
                border-radius: 8.9434px
                // .heading-new-4
                //     font-size: 16px
                // .heading-new-2
                //     font-size: 24px

        .courses-section
            .media-slider
                .slick-slide
                    .slide-content
                        .content
                            video
                                min-height: 175.49px
                                border-radius: 14.6875px
                            .slider-card-content
                                .heading-new-3
                                    font-size: 15.6667px
                                    line-height: 18px
                                p
                                    width: 95%
                .slick-arrow.slick-prev
                    left: 38%
                    &::before
                        font-size: 20px
                .slick-arrow.slick-next
                    right: 38%
                    &::before
                        font-size: 20px
        .event-section
            .event-bg
                height: auto
                padding: 15px
                .event-card
                    // border-radius: 10px
                    padding: 10px
                    border-radius: 13.1809px

@media screen and (max-width: 330px)
    .dashboard-section
        .counter-section
            .counters-card
                padding: 10px
                .heading-new-4
                    font-size: 12px
