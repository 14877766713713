.description-modal {
  min-width: 360px;
  color: white;
  .modal-content {
    border: 2px solid #4398FF;
  }
  .description-modal-container {
    min-height: 300px;
    margin-top: 1rem;
    position: relative;

    .modal-loader {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .header {
      font-size: 1.4rem;
      text-align: center;
      font-family: 'Space Grotesk'
    }
    .icon-and-name {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 2rem;
      img {
        width: 50px;
        height: 50px;
        object-fit: cover;
        background-color: grey;
        border-radius: 50%;
        margin-right: 10px;
      }
      span {
        font-family: 'Space Grotesk';
        font-size: 1.3rem;
      }
    }
    .parter-desc {
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;
      font-family: 'Space Grotesk';
      padding: 0 4%;
      color: #e1e1e1;
      white-space: pre-wrap;
    }
  }
}