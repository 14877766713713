.custom-modal-container {
    .modal-content {
        background: #0D0D0D !important;
        color: white;
        border-radius: 20px !important;
        border: 1px solid #4398FF;
        .close-btn {
            position: absolute;
            right: 17px;
            top: -4px;
            z-index: 1;
            cursor: pointer;
            svg {
                width: 25px;
                height: 25px;
                path {
                    fill: #4398FF;
                }
            }
        }
    }
}