.form-header-section
    .acce-logo
        max-height: 65px
        margin-bottom: 20px
    .accelerate-img
        height: 27px
    .launch-text
        display: flex
        align-items: center
        justify-content: center
        p
            font-family: 'Space Grotesk'
            font-style: normal
            font-weight: 700
            font-size: 13.4141px
            line-height: 80%
            letter-spacing: 0.39em
            text-transform: uppercase
            background: linear-gradient(90.03deg, #4398FF -5.59%, #2479DF 65.86%)
            -webkit-background-clip: text
            -webkit-text-fill-color: transparent
            background-clip: text
            text-fill-color: transparent
            margin-bottom: 0
            margin-right: 10px
        img
            margin-right: 10px
    .form-text
        font-family: 'Space Grotesk'
        font-style: normal
        font-weight: 500
        font-size: 33.2892px
        line-height: 42px
        color: #FFFFFF
        margin-left: 20px
    .counter-card
        background: rgba(68, 68, 68, 0.25)
        border: 1px solid #2F2F2F
        border-radius: 35px
        text-align: center
        padding: 35px
        z-index: 1
        position: relative
        .counter-img
            height: 50px
            width: 50px
            object-fit: contain
            margin: auto
            margin-top: -60px
            margin-bottom: 15px
        h2
            font-family: 'Space Grotesk'
            font-style: normal
            background: linear-gradient(90deg, #4398FF 0%, #2479DF 58.11%)
            -webkit-background-clip: text
            -webkit-text-fill-color: transparent
            background-clip: text
            margin-bottom: 15px
            text-fill-color: transparent
        p
            margin-bottom: 0
@media screen and (max-width: 1200px)
    .form-header-section
        .heading-new-6
            font-size: 14.8424px

@media screen and (max-width: 992px)
    .form-header-section
        .thanks-head-section
            .acce-logo
                max-height: 50px
        .heading-new-6
            font-size: 12.8424px
        button
            padding: 8px 15px !important
@media screen and (max-width: 767px)
    .form-header-section
        .counter-card
            padding: 15px
            border-radius: 12px
            .counter-img
                height: 30px
                width: 30px
                object-fit: contain
                margin: auto
                margin-top: -32px
                margin-bottom: 15px
                .form-header-section .launch-text img
    .form-header-section
        .launch-text
            p
                font-size: 8.4141px
            div
                margin-bottom: 10px
            img
                height: 20px
        .thanks-head-section
            .acce-logo
                max-height: 25px
        .heading-new-6
            font-size: 12.8424px
            line-height: 18px
        button
            padding: 10px 20px !important
            font-size: 9.62783px
            margin-top: 10px
        .data-sections
            display: block !important
            div
                position: relative !important
                text-align: center !important
