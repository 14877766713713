@import '../../../../../variables.sass'

.job-board-container
  color: white
  margin-bottom: 30px
  position: relative
  max-width: 1400px
  margin: auto
  padding: 2%
  box-sizing: border-box
  .container-loader
    content: ""
    background: #64646421
    position: absolute
    top: -24px
    left: -3%
    width: 106%
    height: 118%
    z-index: 99
    display: flex
    justify-content: center
    align-items: center
  .search-remote-container
    margin: 20px 0px
    .remote-sort-item
      width: 80%
      margin: auto
      display: flex
      align-items: center
      justify-content: center
      gap: 3%
    .search-sort-item
      width: 80%
      margin: auto
      display: flex
      align-items: center
      justify-content: center
      gap: 3%
      input
        height: 40px
        width: 70%
        color: white
        background: transparent
        outline: none
        font-size: 14px
        padding-left: 8px
        border: 1.5px solid white
        border-radius: 8px
        &::placeholder
          color: grey
  .filters-container
    margin-bottom: 20px
    .filter-item
      min-height: 160px
      background: $card-background
      padding: 6%
      border-radius: 24px
      .slider-container
        height: 40px
        background: $card-item-background
        padding: 5px 16px 0px
        border-radius: 16px
        margin-bottom: 10px
      .salary-range
        display: flex
        justify-content: space-between
      .roles-container
        display: flex
        flex-wrap: wrap
        justify-content: center
        gap: 2%
        width: 100%
        // height: 40px
        overflow: hidden
        .role-item
          min-width: 48% !important
          &.active
            span
              background: $text-primary-color
              &:hover
                background: $text-primary-color !important
          &:last-child
            span
              margin-bottom: 0
          span
            margin: auto
            background: $card-item-background
            border-radius: 10px
            margin-bottom: 6%
            font-size: 14px
            line-height: 104.5%
            text-align: center
            color: #FFFFFF
            height: 40px
            width: max-content
            padding: 10px
            box-sizing: border-box
            display: block
            cursor: pointer
            &:hover
              background: #303030
              &.selected
                background: $text-primary-color
                      // border: 2px solid #4398FF
                cursor: default
                &.not-selected
.job-card-container
  .job-detail-inside
    display: none
    visibility: hidden
    width: 100%
  // padding: 10px
  &.active
    // background-color: #37373765
    border-radius: 16px 0px 0px 16px
    .job-card-inner
      background-color: inherit
      border-radius: 16px 0px 0px 16px
      cursor: default
      position: relative
      border-right: 3px solid transparent
      &::after
        content: ""
        position: absolute
        width: 120%
        background-color: #1b1b1b
        top: -3px
        left: 0px
        border-radius: 16px 0px 0px 16px
        border: 3px solid #4398FF
        border-right: none
        height: calc(100% + 5.5px)
        z-index: -1
      &:hover
        background-color: inherit !important

  .job-card-inner
    z-index: 2
    height: 200px
    border-radius: 16px
    margin-top: 20px
    padding: 32px 28px 18px 28px
    border: 3px solid transparent
    box-sizing: border-box
    display: flex
    cursor: pointer
    &:hover
      background-color: #adadad40 !important
    .section-1
      display: flex
      width: 50%
      min-width: 158px
      padding-right: 5px
      flex-direction: column
      justify-content: space-between
      .icon-and-name
        display: flex
        flex-direction: column
        gap: 14px
        .project-name
          font-family: 'Space Grotesk'
          font-style: normal
          font-weight: 700
          font-size: 22px
          line-height: 104.5%
          color: #ffffff
          margin: 0
        .image-section
          position: relative
          img
            background: $card-background
            object-fit: cover
            width: 70px
            height: 70px
            min-width: 70px
            min-height: 70px
            border-radius: 50%
            margin-top: -12px
            margin-right: 10px
      .social-icons
        display: flex
        justify-content: space-between
        max-width: 120px
        a
          color: #000
          background: #4398FF
          border-radius: 50%
          cursor: pointer
          width: 27px
          height: 27px
          min-width: 27px
          display: flex
          align-items: center
          justify-content: center
          text-decoration: none
          i
            font-size: 18px
    .section-2
      display: flex
      width: 50%
      flex-direction: column
      justify-content: space-between
      .job-role
        overflow: hidden
        display: -webkit-box
        -webkit-line-clamp: 2
        line-clamp: 2
        -webkit-box-orient: vertical
      .short-desc
        max-height: 72px
        overflow: hidden
        display: -webkit-box
        -webkit-line-clamp: 2
        line-clamp: 2
        -webkit-box-orient: vertical

.job-detail-card-container
  width: 100%
  min-height: 100%
  border-radius: 16px
  margin-top: 22.5px
  position: relative
  // z-index: 2
  // border: 3px solid transparent
  p
    margin: 0
  &::after
    content: ""
    position: absolute
    top: -3px
    left: -3px
    height: calc(100% + 5.5px)
    width: 101%
    border-radius: 16px
    border: 3px solid #4398FF
    // z-index: -3
  .job-detail-card-inner
    position: absolute
    top: 0px
    left: -1px
    width: 100%
    min-height: 100%
    background: #1b1b1b
    border-radius: 16px
    padding: 6%
    height: fit-content
    z-index: 2
    .section-1
      display: flex
      justify-content: space-between
      align-items: center
      width: 100%
      .icon-and-name
        display: flex
        align-items: center
        flex-direction: column
        min-width: 40%
        gap: 16px
        .image-section
          img
            background: $card-background
            width: 80px
            height: 80px
            min-width: 80px
            min-height: 80px
            object-fit: cover
            border-radius: 50%
            margin-right: 20px
        .project-name
          font-family: 'Space Grotesk'
          font-style: normal
          font-weight: 700
          font-size: 26px
          line-height: 104.5%
          color: #ffffff
          margin: 0
          margin-right: 10px
    .section-2
      margin: 20px 0px
      display: flex
      justify-content: space-between
      align-items: center
      button
        border-radius: 40px
    .section-3
      margin-top: 30px
      .job-description
        white-space: pre-line
        word-break: break-word
    .apply-actions
      text-align: center
      button
        min-width: 70%
        border-radius: 40px
    .section-4
      text-align: center
      height: 115px
      .jd-footer
        position: absolute
        width: 100%
        bottom: 50px
        left: 0px
        .social-icons
          display: flex
          justify-content: space-between
          max-width: 140px
          margin: auto
          a
            color: #000
            background: #4398FF
            border-radius: 50%
            cursor: pointer
            width: 27px
            height: 27px
            min-width: 27px
            display: flex
            align-items: center
            justify-content: center
            text-decoration: none

@media screen and (max-width: 900px)
  .search-remote-container
    .remote-sort-item
      width: 100% !important
    .search-sort-item
      width: 100% !important
      margin-top: 20px !important
      input
        width: 76% !important
  .filters-container
    .filter-item
      min-height: auto !important
      padding: 4% !important
  .job-card-container
    margin-top: 20px
    .apply-btn-container
      position: relative
      display: flex
      justify-content: center
      height: 30px
      margin-top: 10px
      margin-bottom: 5px
      .apply-button
        position: absolute
        top: -10px
        width: 30%

    &.active
      background-color: #32323265
      border: 3px solid #4398FF
      border-radius: 16px !important
    .job-card-inner
      background: transparnet
      margin-top: 0px !important
      border-radius: 16px !important
      height: 180px
      &::after
        content: none !important
      .section-1
        width: 70% !important
        .icon-and-name
          .image-section
            img
              width: 50px
              height: 50px
              min-width: 50px
              min-height: 50px
          .project-name
            font-size: 18px !important
            word-break: break-all
        .social-icons
          display: flex
          justify-content: space-between
          max-width: 100px
        a
          color: #000
          background: #4398FF
          border-radius: 50%
          cursor: pointer
          width: 22px !important
          height: 22px !important
          min-width: 22px !important
          display: flex
          align-items: center
          justify-content: center
          text-decoration: none
          i
            font-size: 16px !important
            margin-top: 2px
      .section-2
        .job-role
          -webkit-line-clamp: 5
          line-clamp: 5
        .sup-new-small
          display: none
        .short-desc
          max-height: 74px
  .job-detail-card-container
    margin-top: 0px
    &::after
      content: none !important
    .job-detail-card-inner
      background: none
      padding-top: 0px
      .section-1
        display: none
      .section-2
        display: none
      .section-3
        margin-top: 0px
      .section-4
        margin-top: 20px !important
        height: 100px !important
        button
          margin-top: 20px !important
