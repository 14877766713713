@import "../../../../../../../variables.sass"

.offer-table-section
    background: $card-background
    // border: 1px solid #2F2F2F
    border-radius: 18px
    padding: 40px 70px
    &.scrolled
        height: 1300px
        overflow-y: scroll
        overflow-x: hidden
    .vc-list-card
        background: $card-item-background
        border-radius: 17px
        padding: 20px 35px
        // box-shadow: 0px 3px 6px rgba(84, 196, 252, 0.5)
        width: 85%
        margin: auto
        min-height: 311px
        display: flex
        flex-direction: column
        justify-content: space-between
        .company-name-text
            display: -webkit-box
            -webkit-line-clamp: 1
            -webkit-box-orient: vertical
            overflow: hidden
        .company-operations-text
            display: -webkit-box
            -webkit-line-clamp: 2
            -webkit-box-orient: vertical
            overflow: hidden
            height: 42px
        .discount-partner-icon
            border: 1px solid #fff
            border-radius: 50%
            padding: 5px
            object-fit: cover
            height: 90px
            width: 90px
            min-height: 90px
            min-width: 90px
        .view-discount-item
            display: flex
            justify-content: center
            .view-discount-btn
                display: flex
                align-items: center
                gap: 8px
                padding: 4px 18px
                font-size: 14px
                img
                    width: 28px
        .social-link-table
            display: flex
            align-items: center
            justify-content: center
            gap: 25px
            padding-left: 0
            margin-bottom: 0
            li
                list-style: none
                a
                    width: 21px
                    height: 21px
                    min-width: 21px
                    min-height: 21px
                    color: black
                    background: #4398FF
                    display: flex
                    align-items: center
                    justify-content: center
                    text-decoration: none
                    border-radius: 50%
        .auditing
            font-family: 'Space Grotesk'
            font-style: normal
            font-weight: 300
            font-size: 19.3418px
            line-height: 25px
            text-align: center
            color: #FFFFFF
        .avail
            font-family: 'Space Grotesk'
            font-style: normal
            font-weight: 500
            font-size: 24px
            line-height: 31px
            text-align: center
            background: linear-gradient(180deg, #2EAAF3 0%, #2EAAF3 100%)
            -webkit-background-clip: text
            -webkit-text-fill-color: transparent
            background-clip: text
            text-fill-color: transparent

        .request-to-onnect-btn
            // background: #44444440 !important
            border: 1px solid #4398FF !important
            border-radius: 12px !important
            border-width: 2px !important
            width: 80%
            &:hover
                background: $card-item-hover-background

@media screen and (max-width: 1700px)
    .offer-table-section
        .vc-list-card
            padding: 20px 20px
            width: 95%
            .discount-partner-icon
                width: 80px
                height: 80px
                width: 80px
                min-height: 80px
                min-width: 80px
            .avail
                font-size: 20px

@media screen and (max-width: 1600px)
    .offer-table-section
        .vc-list-card
            padding: 20px 20px
            width: 100%
            .discount-partner-icon
                width: 70px
                height: 70px
                width: 70px
                min-height: 70px
                min-width: 70px

@media screen and (max-width: 1400px)
    .offer-table-section
        padding: 40px
        .vc-list-card
            min-height: 290px
            .avail
                font-size: 17px

@media screen and (max-width: 1200px)
    .offer-table-section
        padding: 40px
        .vc-list-card
            .company-operations-text
                height: 38px

@media screen and (max-width: 767px)
    .offer-table-section
        padding: 10px
        .vc-list-card
            padding: 20px 8px
            min-height: 180px
            max-width: 320px
            border-radius: 12px
            .view-discount-item
                margin-top: 16px
                .view-discount-btn
                    font-size: 12px
                    padding: 2px 10px
                    img
                        width: 22px
            .avail
                font-size: 11.5325px
                line-height: 14px
                letter-spacing: 0
            .heading-new-5
                font-size: 12px
            .social-link-table
                gap: 10px
                li
                    a
                        width: 13px
                        height: 13px
                        min-width: 13px
                        min-height: 13px
                        font-size: 8px
            .request-to-onnect-btn
                margin-top: 14px !important
                width: max-content
                font-size: 12px !important
                line-height: 9px !important
                padding: 10px 20px !important
