.onbord-section
    position: relative
    .thank-text-section
        background: rgba(68, 68, 68, 0.25)
        border: 2px solid #4398FF
        border-radius: 25.93px
        width: 100%
        text-align: center
        padding: 30px 100px
        .media-slider
            .slick-slide
                .slide-content
                    width: 95% !important
                    margin: auto !important
                    .content
                        img, video
                            border-radius: 28.6875px
                            height: 300px
                            min-height: 300px
                            width: 100%
                            object-fit: cover
    .form-header-section
        .thanks-head-section
            // .heading-new-6
            //     display: none
    // .media-slider
    //     iframe
    //         height: 300px
    //         width: 100%
    //         border-radius: 28px
        // .slick-slider
        //     .slick-track
        //         margin-left: inherit
@media screen and (max-width: 1200px)
    .onbord-section
        .thank-text-section
            padding: 30px 60px
            .slide-content
                .slider-card-content
                    h3
                        font-size: 24.6667px
                        line-height: 30px
                    p
                        font-size: 18.8424px
                        line-height: 25px
@media screen and (max-width: 992px)
    .onbord-section
        .thank-text-section
            padding: 30px

@media screen and (max-width: 767px)
    .onbord-section
        .thank-text-section
            padding: 4px
            .media-slider
                .slick-slide
                    .slide-content
                        .content
                            video
                                min-height: 131.84px
                                border-radius: 12.6875px
                        .slider-card-content
                            h3
                                font-size: 12.6667px
                                line-height: 5px
                            p
                                font-size: 10px
                                line-height: 12.76px
        .new-black-button
            font-size: 10px
            padding: 8px 15px
            margin-bottom: 20px