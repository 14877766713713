@import "../../../../../../variables.sass";

.job-board-animation-container {
  position: relative;
  margin: 0;
  overflow: hidden;
  height: 600px;
  width: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;

  .heading {
    position: absolute;
    top: 8%;
    z-index: 1;
    font-family: 'Space Grotesk';
    font-style: normal;
    font-weight: 700;
    font-size: 3.5rem;
  }

  .subscribe-item {
    position: absolute;
    bottom: 8%;
    z-index: 1;
    display: flex;
    align-items: center;
    width: 400px;
    gap: 10px;

    input {
      height: 36px;
      width: 300px;
      color: white;
      background: transparent;
      outline: none;
      font-size: 14px;
      padding-left: 5px;
      border: 1.5px solid white;
      border-radius: 8px;
    }

    button {
      width: 120px;
      text-align: center;
      padding: 10px;
      border-radius: 8px;
    }
  }

  .center-ball-container {
    position: absolute;
    transform: scale(0.5);
    animation: scaleInOut 2s linear infinite;
  }

  .hexagon {
    position: absolute;
    -webkit-animation: spin 4s linear infinite;
    -moz-animation: spin 4s linear infinite;
    animation: spin 4s linear infinite;

    @-moz-keyframes spin {
      100% {
        -moz-transform: rotate(360deg);
      }
    }

    @-webkit-keyframes spin {
      100% {
        -webkit-transform: rotate(360deg);
      }
    }

    @keyframes spin {
      100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }

    &.one {
      top: 32%;
      right: 16%;
      width: 70px;
    }

    &.two {
      top: 25%;
      right: 41%;
      width: 36px;
    }

    &.three {
      width: 42px;
      left: 26%;
      top: 25%;
    }

    &.four {
      width: 42px;
      left: 12%;
      bottom: 38%;
    }

    &.five {
      width: 42px;
      bottom: 17%;
      left: 40%;
    }

    &.six {
      width: 28px;
      bottom: 28%;
      left: 71%;
    }
  }

  @keyframes scaleInOut {

    0%,
    100% {
      transform: scale(0.6);
    }

    50% {
      transform: scale(0.8);
    }
  }

  #job-ripple-container {
    position: absolute;
    margin-left: -100px;
    margin-top: -100px;
  }

  .ripple {
    height: 100px;
    width: 100px;
    position: absolute;
    border-radius: 50%;
    border: 1px solid #4398FF;
    transform: scale(1);
    // animation: ripple-animation 2s linear;
  }

  @keyframes ripple-animation {
    to {
      transform: scale(6);
      opacity: 0;
    }
  }
}

@media screen and (max-width: 550px) {
  .job-board-animation-container {
    height: 500px;
    width: 500px;

    .heading {
      top: 12%;
      font-size: 2.5rem;
    }

    .subscribe-item {
      position: absolute;
      bottom: 10%;
      width: 100%;
      padding: 0px 10px;

      input {
        height: 32px;
        width: 80% !important;
        color: white;
        background: transparent;
        outline: none;
        font-size: 14px;
        padding-left: 5px;
        border: 1.5px solid white;
        border-radius: 8px;
      }

      button {
        text-align: center;
        padding: 10px;
        border-radius: 8px;
      }
    }

    .ripple {
      height: 80px;
      width: 80px;
    }

    #job-ripple-container {
      margin-left: -80px;
      margin-top: -80px;
    }

    @keyframes scaleInOut {

      0%,
      100% {
        transform: scale(0.4);
      }

      50% {
        transform: scale(0.6);
      }
    }

    .hexagon {
      &.one {
        top: 32%;
        right: 16%;
        width: 50px;
      }

      &.two {
        top: 25%;
        right: 41%;
        width: 22px;
      }

      &.three {
        width: 32px;
        left: 26%;
        top: 25%;
      }

      &.four {
        width: 32px;
        left: 12%;
        bottom: 38%;
      }

      &.five {
        width: 32px;
        bottom: 25%;
        left: 40%;
      }

      &.six {
        width: 22px;
        bottom: 32%;
        left: 71%;
      }
    }
  }
}