.video-details
    .card-player
        video
            border-radius: 24.4487px
.event-dropdown
    display: flex
    align-items: center
.event-header-content
    display: flex
    align-items: center
    justify-content: space-between
.event-button
    .menuItem
        background: rgba(68, 68, 68, 0.25)
        border: 1px solid #2F2F2F
        border-radius: 10px
        font-family: 'Space Grotesk'
        font-style: normal
        font-weight: 700
        font-size: 16px
        line-height: 104.5%
        text-align: center
        width: 170px
        height: 42px
        color: #FFFFFF
        &:first-child
            margin-right: 20px
        &.selectedMenu
            background: linear-gradient(180deg, #4398FF 0%, #2479DF 100%)
            border: 1px solid #2479DF
.event-text-card-section
    .calender-view-iframe
        height: 600px
        width: 100%

@media screen and (max-width: 1400px)
    .event-text-card-section
        .calender-view-iframe
            height: 500px
@media screen and (max-width: 1200px)
    .event-button
        .menuItem
            font-size: 14px
            width: 160px
            height: 40px

@media screen and (max-width: 992px)
    .event-button
        .menuItem
            font-size: 12px
            width: 140px
            height: 32px
            border-radius: 4px
    .event-text-card-section
        .calender-view-iframe
            height: 400px

@media screen and (max-width: 767px)
    .event-header-content
        display: block
    .event-button
        text-align: center
        .menuItem
            font-size: 9px
            width: 120px
            height: 24px
            line-height: normal
    .event-text-card-section
        .calender-view-iframe
            height: 400px
            width: 600px
        .calender-view-tab
            overflow-x: scroll
            overflow-y: hidden
        .video-details
            .card-player
                video
                    border-radius: 17.9289px