@import "../../../../../../../variables.sass"

.update-section
    min-height: 100px
    .update-table
        background: $card-background
        // border: 1px solid #2F2F2F
        border-radius: 18px
        padding: 5px 45px
        .loader-section
            height: 100%
            width: 100%
            display: flex
            justify-content: center
            align-items: center
            min-height: 100px
        &.scrolled
            max-height: 450px
            overflow-x: hidden
            overflow-y: auto
        .update-table-field
            padding: 12px 0
            border-bottom: 1px solid #414141
            margin-top: 6px
            .update-date-item
                display: flex
                justify-content: end
                span
                    color: white
                    font-size: 12px
            &:last-child
                border-bottom: none
            img
                width: 55px
                height: 55px
                min-width: 55px
                min-height: 55px
                object-fit: cover
                border-radius: 50%

@media screen and (max-width: 1400px)
    .update-section
        .update-table
            padding: 20px 30px
            .update-table-field
                padding: 20px 0
@media screen and (max-width: 767px)
    .update-section
        .update-table
            padding: 0 10px
            &.scrolled
                max-height: 644px
            .update-table-field
                padding: 12px 0
                .update-date-item
                    padding-top: 5px
                    span
                        font-size: 10px
                img
                    width: 35px
                    height: 35px
                    min-width: 35px
                    min-height: 35px
                p
                    font-size: 12px
