.list-section
    position: relative
    .thank-text-section
        background: rgba(68, 68, 68, 0.25)
        border: 2px solid #4398FF
        border-radius: 25.93px
        width: 100%
        text-align: center
        padding: 30px 100px
        .connection-section
            .all-mentor-section
                p
                    display: -webkit-box
                    -webkit-line-clamp: 2
                    -webkit-box-orient: vertical
                    overflow: hidden
                    text-overflow: ellipsis
                    min-height: 44px

@media screen and (max-width: 1600px)
    .list-section
        .thank-text-section
            padding: 30px 50px

@media screen and (max-width: 1200px)
    .list-section
        .thank-text-section
    .form-header-section
        .acce-logo
            max-height: 50px
            margin-bottom: 15px

@media screen and (max-width: 992px)
    .list-section
        .thank-text-section
            .paragraph-new
                br
                    display: none

@media screen and (max-width: 767px)
    .list-section
        .thank-text-section
            padding: 20px
            .paragraph-new
                br
                    display: none
            .connection-section
                .accelerate-list-slider
                    padding: 0 30px
                    .slick-prev, .slick-next
                        z-index: 11
                    .slick-prev
                        border: 1px solid #fff
                        border-radius: 50%
                        display: flex
                        align-items: center
                        justify-content: center
                        height: 20px
                        width: 20px
                        &::before
                            font-size: 12px
                            content: "\ea64"
                            font-family: remixicon!important
                            font-style: normal
                            -webkit-font-smoothing: antialiased
                    .slick-next
                        display: flex
                        align-items: center
                        justify-content: center
                        height: 20px
                        border: 1px solid #fff
                        border-radius: 50%
                        width: 20px
                        &::before
                            font-size: 12px
                            content: "\ea6e"
                            font-family: remixicon!important
                            font-style: normal
                            -webkit-font-smoothing: antialiased


@media screen and (max-width: 330px)
    .list-section 
        .thank-text-section 
            .connection-section 
                .accelerate-list-slider
                    padding: 0 15px