@import './variables.sass'
@tailwind base
@tailwind components
@tailwind utilities

body
    background: #000 !important
    -webkit-font-smoothing: antialiased
    -moz-osx-font-smoothing: grayscale
    overflow: auto !important
    overflow-x: hidden !important
    background-color: black !important

.main-tab-panel-container
    background: $background-main
    border-radius: 16px
    height: fit-content
    // padding: 0% 6%
.color-primary
    color: $text-primary-color !important
.hover-primary
    &:hover
        color: $text-primary-color !important
.container-maxwidth
    max-width: 1920px !important

h1, h2, h3, h4, h5, h6, p, span, label, a, button, input, div, th, td
    font-family: 'Space Grotesk' !important
    margin: 0px
    padding: 0px
.heading-new-1
    font-family: 'Space Grotesk'
    font-style: normal
    font-weight: 700
    font-size: 68px
    line-height: 104.5%
    color: #FFFFFF
.heading-new-2
    font-family: 'Space Grotesk'
    font-style: normal
    font-weight: 700
    font-size: 46px
    color: #ffffff
    line-height: 104.5%
.heading-new-3
    font-family: 'Space Grotesk'
    font-style: normal
    font-weight: 700
    font-size: 34px
    color: #ffffff
    line-height: 120%
    // text-align: center
.heading-new-4
    font-family: 'Space Grotesk'
    font-style: normal
    font-weight: 600
    font-size: 26px
    line-height: 130.5%
    color: #FFFFFF
.padding-top-80
    padding-top: 85px
.padding-top-60
    padding-top: 60px

.uppercase
    text-transform: uppercase
span
    font-family: 'Space Grotesk' !important
    font-style: normal !important
.heading-new-5
    font-family: 'Space Grotesk'
    font-style: normal
    font-weight: 700
    font-size: 22px
    line-height: 104%
    color: #ffffff
.fs-82
    font-family: 'Space Grotesk'
    font-style: normal
    font-weight: 700
    font-size: 53.2632px
    line-height: 75px
    color: #FFFFFF
.fs-47
    font-family: 'Space Grotesk'
    font-style: normal
    font-weight: 700
    font-size: 36px
    line-height: 104.5%
    color: #FFFFFF
.heading-new-6
    font-family: 'Space Grotesk'
    font-style: normal
    font-weight: 300
    font-size: 21.8424px
    line-height: 28px
    text-align: center
    color: #FFFFFF
.paragraph-new
    font-family: 'Space Grotesk'
    font-style: normal
    font-weight: 400
    font-size: 16px
    line-height: 23px
    color: #FFFFFF
    margin-bottom: 1rem
.paragraph-new-medium
    font-family: 'Space Grotesk'
    font-style: normal
    font-weight: 300
    font-size: 16px
    line-height: 140%
    color: #ffffff
.line-height-p-140
    font-size: 13px
    line-height: 140% !important
.paragraph-new-small
    font-family: 'Space Grotesk'
    font-style: normal
    font-size: 14.6796px
    color: #ffffff
    // line-height: 100%
.sup-new-small
    font-family: 'Space Grotesk'
    font-style: normal
    font-weight: 700
    font-size: 13.7566px
    line-height: 104.5%
    color: #FFFFFF
.new-primary-button
    background: rgba(68, 68, 68, 0.25)
    border: 2px solid #4398FF
    border-radius: 30px !important
    font-family: 'Space Grotesk'
    font-style: normal
    font-weight: 500
    font-size: 16px
    line-height: 80%
    padding: 12px 25px
    color: #ffffff
    &:hover
        background: linear-gradient(180deg, #4398FF 0%, #2479DF 100%)
        transition: 0.6s
.color-white-new
    color: #FFFFFF
.color-primary-new
    color: #4398FF
    font-size: 20px
.new-color-button
    background: linear-gradient(180deg, #4398FF 0%, #2479DF 100%)
    border: 1px solid #2479DF
    border-radius: 20px
    font-family: 'Space Grotesk'
    font-style: normal
    font-weight: 500
    font-size: 16px
    line-height: 110%
    padding: 12px 25px
    color: white
    &:hover
        background: transparent
        border: 1px solid #4398FF
        transition: 0.6s
.new-color-button-small
    background: linear-gradient(180deg, #4398FF 0%, #2479DF 100%)
    border-radius: 50px
    border: none
    font-family: 'Space Grotesk'
    font-style: normal
    font-weight: 400
    font-size: 11.8154px
    line-height: 104.5%
    padding: 8px 15px
    color: white
    &:hover
        background: linear-gradient(180deg, #4398FF 0%, #2479DF 100%)
        transition: 0.6s
.public-viewing-btn
    background: linear-gradient(270deg, #4398FF 0%, rgba(84, 196, 252, 0) 100%)
    border: 2px solid #4398FF
    border-radius: 15px
    font-family: 'Space Grotesk'
    font-style: normal
    font-weight: 500
    font-size: 16px
    line-height: 80%
    padding: 15px 25px
    color: #ffffff
.first-letter-capital
    &::first-letter
        text-transform: uppercase
.new-anim-btn
    background: rgba(24, 178, 255, 0.1)
    border-radius: 16px
    font-family: 'Space Grotesk'
    font-style: normal
    font-weight: 300
    font-size: 20px
    line-height: 31px
    text-align: center
    color: #ffffff
    border: 2px solid #18B2FF1A
    padding: 10px 30px
    display: flex
    margin: auto
    position: relative
    overflow: hidden
    .anim-span
        background: linear-gradient(270deg, #4398FF 5.08%, rgba(255, 255, 255, 0) 112.4%)
        border-radius: 4.93956px
        height: 1.65px !important
        width: 100px
        display: flex
        top: 5px
        left: 0
        position: absolute
        animation-direction: alternate-reverse
        animation: getanimbtn 4s linear infinite
        z-index: -1
        &.anim-2
            top: 24px
            animation: getanimbtn 7s linear infinite
        &.anim-3
            top: 15px
            animation: getanimbtn 2s linear infinite
        &.anim-4
            top: 51px
            animation: getanimbtn 5s linear infinite
        &.anim-5
            top: 45px
            animation: getanimbtn 8s linear infinite
        @keyframes getanimbtn
            0%
                left: 0
            100%
                left: 100%

.new-primary-button-small
    background: rgba(68, 68, 68, 0.25)
    border: 1px solid #4398FF
    border-radius: 10px
    font-family: 'Space Grotesk'
    font-style: normal
    font-weight: 400
    font-size: 12px
    line-height: 104.5%
    padding: 8px 15px
    color: white
    position: relative
    overflow: hidden
    span
        background: linear-gradient(180deg, #FFFFFF 0%, #7AD3FF 100%)
        -webkit-background-clip: text
        -webkit-text-fill-color: transparent
        background-clip: text
        text-fill-color: transparent
    .anim-span
        background: linear-gradient(270deg, #4398FF 5.08%, rgba(255, 255, 255, 0) 112.4%)
        border-radius: 4.93956px
        height: 1.65px !important
        width: 50px
        display: flex
        top: 5px
        left: 0
        position: absolute
        animation-direction: alternate-reverse
        animation: buttonanimName 4s linear infinite
        z-index: -1
        &.anim-2
            top: 24px
            animation: buttonanimName 7s linear infinite
        &.anim-3
            top: 9px
            animation: buttonanimName 3s linear infinite
        &.anim-4
            top: 22px
            animation: buttonanimName 5s linear infinite
        &.anim-5
            top: 18px
            animation: buttonanimName 6s linear infinite
    @keyframes buttonanimName
        0%
            left: 0
        100%
            left: 100%
.new-black-button
    background: #44444440 !important
    border: 1px solid #2F2F2F !important
    border-radius: 10px !important
    font-family: 'Space Grotesk' !important
    font-style: normal
    font-weight: 500
    font-size: 16px
    line-height: 80%
    padding: 8px 25px
    color: white
    &:hover
        background: linear-gradient(180deg, #4398FF 0%, #2479DF 100%) !important
        transition: 0.6s
    &:active
        background: linear-gradient(180deg, #4398FF 0%, #2479DF 100%) !important
.new-white-button
    background: transparent !important
    border: 1px solid #A1A1A1 !important
    border-radius: 8.7802px !important
    font-family: 'Space Grotesk' !important
    font-style: normal
    font-weight: 500
    font-size: 16px
    line-height: 80%
    padding: 10px 35px
    color: #ffffff
    &:hover
        border: 1px solid #4398FF !important
        color: #4398FF
        transition: 0.6s
    &:active
        background: linear-gradient(180deg, #4398FF 0%, #2479DF 100%) !important
        color: #fff
.pointer-event-none
    pointer-events: none
.animation-button
    background: transparent
    border: 1px solid #4398FF
    border-radius: 26px
    font-family: 'Space Grotesk'
    font-style: normal
    font-weight: 300
    font-size: 8.6px
    line-height: 11px
    display: flex
    align-items: center
    color: #FFFFFF
    padding: 3px 10px
::-webkit-scrollbar
    width: 8px
    height: 8px
    scrollbar-width: thin
    scrollbar-color: #ff7f00 #b0b7c4

::-webkit-scrollbar-track
    background-clip: content-box
    border: 3px solid transparent

::-webkit-scrollbar-thumb
    background: linear-gradient(180deg, #4398FF 0%, #2479DF 100%)
    border-radius: 10px

::-webkit-scrollbar-thumb:hover
    background-color: #e67200

::-webkit-scrollbar-corner,
::-webkit-scrollbar-track
    background-color: #b0b7c4

.homepage-container
    width: 100%
    color: white
    box-sizing: border-box
    .welcome-to-container
        margin: 0 auto 0
        padding: 0 0 50px 0px
        position: relative
        &::after
            content: ""
            position: absolute
            left: 21%
            top: 37%
            transform: translate(-50%, -50%)
            background-image: url(./assests/images/homepage/launchpad-bg.svg)
            background-position: center
            background-size: contain
            background-repeat: no-repeat
            width: 100%
            height: 100%
            z-index: -1
        .background-logo
            background-image: url(./assests/images/homepage/hero-main-img-bg.svg)
            background-repeat: no-repeat
            background-size: contain
            background-position: center
            display: flex
            justify-content: center
            align-items: center
            img
                width: 50%
                padding: 80px 0
        .square-red-1
            position: absolute
            left: 24%
            top: 30%
            height: 18%
            animation-direction: alternate-reverse
            animation: launchpadanimName 4s linear infinite
            z-index: 2
        .square-red-2
            position: absolute
            left: 37%
            top: 10%
            animation-direction: alternate-reverse
            animation: launchpadanimName 6s linear infinite
            z-index: 2
        .square-red-3
            position: absolute
            left: 47%
            top: 24%
            height: 20%
            animation-direction: alternate-reverse
            animation: launchpadanimName 9s linear infinite
            z-index: 2
        .square-red-4
            position: absolute
            left: 42%
            top: 43%
            animation-direction: alternate-reverse
            animation: launchpadanimName 10s linear infinite
            z-index: 2
        .square-red-5
            position: absolute
            left: 51%
            top: 2%
            height: 13%
            animation-direction: alternate-reverse
            animation: launchpadanimName 6s linear infinite
            z-index: 2
        .square-red-6
            position: absolute
            left: 62%
            top: 8%
            animation-direction: alternate-reverse
            animation: launchpadanimName 15s linear infinite
            z-index: 2
        .square-red-7
            position: absolute
            left: 59%
            top: 42%
            height: 20%
            animation-direction: alternate-reverse
            animation: launchpadanimName 13s linear infinite
            z-index: 2
        .square-red-8
            position: absolute
            left: 72%
            top: 24%
            height: 20%
            animation-direction: alternate-reverse
            animation: launchpadanimName 11s linear infinite
            z-index: 2
        .square-red-9
            position: absolute
            left: 56%
            top: 23%
            height: 20%
            animation-direction: alternate-reverse
            animation: launchpadanimName 7s linear infinite
            z-index: 2
        @keyframes launchpadanimName
            0%
                // top: 50%
                transform: translateY(0)

            50%
                // top: 0
                transform: translateY(-50px)

            100%
                // top: 50%
                transform: translateY(0)
        .welcome-content
            p
                font-family: 'Space Grotesk'
            h2
                color: #ffffff

            .live-arbitrum
                display: flex
                align-items: center
                margin: 20px 0 30px 0
                p
                    font-family: 'Space Grotesk'
                    font-style: normal
                    font-weight: 700
                    font-size: 13.4141px
                    line-height: 80%
                    letter-spacing: 0.39em
                    text-transform: uppercase
                    background: linear-gradient(90.03deg, #4398FF -5.59%, #2479DF 65.86%)
                    -webkit-background-clip: text
                    -webkit-text-fill-color: transparent
                    background-clip: text
                    text-fill-color: transparent
                    margin-bottom: 0
                    margin-right: 20px
            .logo-flex
                display: flex
                align-items: center
                p
                    letter-spacing: 0
                img
                    max-height: 45px
        .actions-buttons

    .counter-card
        background: rgba(68, 68, 68, 0.25)
        border: 1px solid #2F2F2F
        border-radius: 35px
        text-align: center
        padding: 35px
        z-index: 1
        position: relative
        .counter-img
            height: 50px
            width: 50px
            object-fit: contain
            margin: auto
            margin-top: -55px
            margin-bottom: 15px
        h2
            font-family: 'Space Grotesk'
            font-style: normal
            background: linear-gradient(90deg, #4398FF 0%, #2479DF 58.11%)
            -webkit-background-clip: text
            -webkit-text-fill-color: transparent
            background-clip: text
            margin-bottom: 15px
            text-fill-color: transparent
        p
            margin-bottom: 0

    .how-we-work-section
        position: relative
        &::before
            content: ""
            position: absolute
            left: 0px
            top: 50%
            transform: translateY(-50%)
            width: 100%
            height: 150%
            background-image: url(./assests/images/homepage/how-we-work-bg.svg)
            background-size: contain
            background-repeat: no-repeat
        .how-we-work
            padding: 40px 0 50px 0
            background: rgba(0, 0, 0, 0.8)
            position: relative
            z-index: 1
            h2
                margin: 10px 0 20px 0
            .how-we-work-card
                display: flex
                flex-direction: column
                justify-content: space-between
                .bin-scal-number
                    -webkit-text-fill-color: transparent
                    -webkit-text-stroke-color: #4398FF
                    -webkit-text-stroke-width: 2px
                    display: block
                    font-family: 'Space Grotesk'
                    font-style: normal
                    font-weight: 700
                    font-size: 123.75px
                    line-height: 80%
                h2
                    margin: 10px 0 20px 0
                h6
                    font-family: 'Space Grotesk'
                    font-style: normal
                    font-weight: 700
                    font-size: 25px
                    line-height: 32px
                    margin-bottom: 30px
                p
                    strong
                        font-family: 'Space Grotesk'
                        font-weight: 600

    .powerd-by-section
        position: relative
        padding: 40px 0 50px 0
        .powerd-by-text
            h2
            button
                display: flex
                align-items: center
                background: rgba(68, 68, 68, 0.25)
                border-radius: 29px
                padding: 8px 17px
                margin: 20px 0 30px 0
                font-family: 'Space Grotesk'
                font-style: normal
                font-weight: 400
                font-size: 18px
                line-height: 23px
                border: 1px solid #4398FF
                img
                    margin-left: 10px
            i
                font-size: 40px
            p
    .chains-with-section
        padding: 50px 0
        position: relative
        z-index: 1
        h2
            .project-count-img
                margin-top: -50px
            .square-red-1
                position: absolute
                left: 18%
                top: 40%
                height: 13%
                animation-direction: alternate-reverse
                animation: launchpadanimName 4s linear infinite
                z-index: 2
            .square-red-2
                position: absolute
                left: 23%
                top: 52%
                height: 14%
                animation-direction: alternate-reverse
                animation: launchpadanimName 6s linear infinite
                z-index: 2
            .square-red-3
                position: absolute
                left: 28%
                top: 39%
                height: 12%
                animation-direction: alternate-reverse
                animation: launchpadanimName 9s linear infinite
                z-index: 2
            .square-red-4
                position: absolute
                left: 32%
                top: 30%
                height: 13%
                animation-direction: alternate-reverse
                animation: launchpadanimName 10s linear infinite
                z-index: 2
            .square-red-5
                position: absolute
                left: 38%
                top: 22%
                height: 13%
                animation-direction: alternate-reverse
                animation: launchpadanimName 6s linear infinite
                z-index: 2
            @keyframes launchpadanimName
                0%
                    // top: 50%
                    transform: translateY(0)

                50%
                    // top: 0
                    transform: translateY(-50px)

                100%
                    // top: 50%
                    transform: translateY(0)
        button
            margin-top: 20px
        .chain-icon-h2
            margin: 20px 0 30px 0
        .chain-partner
            display: grid
            grid-template-columns: auto auto
            gap: 27px
            .partner-img
                padding: 10px 40px
                background: rgba(68, 68, 68, 0.25)
                border: 1px solid #2F2F2F
                border-radius: 35px
                img
                    height: 45px
        p.last-text
            margin: 20px 0 0 0
            color: #FFFFFF
    .past-performance-section
        position: relative
        &:after
            content: ""
            position: absolute
            top: 50%
            right: 0
            transform: translateY(-50%)
            width: 100%
            height: 120%
            background-image: url(./assests/images/homepage/post-bg.svg)
            background-position: right
            background-repeat: no-repeat
            background-size: contain
        .past-performance
            position: relative
            z-index: 1
            padding: 50px 0
            background: rgba(0, 0, 0, 0.75)
            .past-performance-card
                width: 80%
                margin: auto
                margin-bottom: 30px
                border: 3px solid
                border: 2px solid #4398FF
                background: rgba(68, 68, 68, 0.25)
                border-radius: 35px
                padding: 30px
                text-align: center
                img
                    height: 100px
                    margin-bottom: 30px
                h2
                    text-align: center
                    color: #FFFFFF
                p
                    color: #FFFFFF
            .many-more
                p
                    color: #FFFFFF
                .tag-slider
                    .tag-slide-flex
                        animation: movescale7 25s linear infinite
                        -webkit-animation: movescale7 25s linear infinite
                        display: flex
                        align-items: center
                        flex-wrap: wrap
                        gap: 20px
                        width: 2500px
                        position: relative

                @keyframes movescale7
                    0%
                        translate: 0%
                    50%
                        translate: -25%
                        animation-delay: 2s
                    100%
                        translate: 0%

                // .tag-slider-item
                //     display: flex
                //     align-items: center
                //     gap: 10px
                //     padding: 10px 15px
                //     background-color: #6A8C99
                //     border-radius: 40px
                //     width: fit-content
                //     img
                //         width: 30px
                //         height: 30px
                //         min-width: 30px
                //         min-height: 30px
                //         border-radius: 50%
                //         object-fit: cover
    .project-belong-world-section
        padding: 50px 0
        position: relative
        z-index: 1
    .partner-ecosystem-section
        position: relative
        &::before
            content: ""
            position: absolute
            left: 0px
            top: 50%
            transform: translateY(-50%)
            width: 100%
            height: 150%
            background-image: url(./assests/images/homepage/partner-rectangle-bg.svg)
            background-position: right
            background-size: contain
            background-repeat: no-repeat
        .partner-ecosystem
            padding: 50px 0
            overflow: hidden
            background: rgba(0, 0, 0, 0.8)
            position: relative
            z-index: 1
            h2
                text-align: center
            p
                color: #FFFFFF
                margin-bottom: 0
            .slick-slider
                padding-bottom: 100px
            .slick-arrow.slick-prev
                position: absolute
                left: 46%
                top: 85%
                border: 1px solid #fff
                border-radius: 50%
                display: flex
                align-items: center
                justify-content: center
                height: 30px
                width: 30px
                &::before
                    content: "\ea64"
                    font-family: remixicon!important
                    font-style: normal
                    -webkit-font-smoothing: antialiased
            .slick-arrow.slick-next
                position: absolute
                left: 51%
                top: 85%
                display: flex
                align-items: center
                justify-content: center
                height: 30px
                border: 1px solid #fff
                border-radius: 50%
                width: 30px
                &::before
                    content: "\ea6e"
                    font-family: remixicon!important
                    font-style: normal
                    -webkit-font-smoothing: antialiased
        .anxious-slide-item
            background: rgba(68, 68, 68, 0.25)
            border: 0.669525px solid #2F2F2F
            border-radius: 23.4334px
            padding: 30px
            margin: auto
            height: 160px
            width: 90% !important
            display: flex !important
            flex-direction: column
            justify-content: space-between
            img
                margin: 0 auto
                max-height: 70px
                margin-bottom: 17px

    .contact-us-section
        padding: 50px 0
        position: relative
        z-index: 1
        .center-line
            height: 100%
            background: #434343
            width: 3px
            height: 300px
            margin: auto
        h2
            color: #FFFFFF
        p
            color: #FFFFFF
        button
    .investor-section
        padding: 50px 0
        position: relative
        z-index: 1
        .heading
            font-family: 'Space Grotesk'
            font-style: normal
            font-weight: 500
            font-size: 24px
            line-height: 80%
            text-align: center
            color: #FFFFFF

    .belong-world-counter
        padding: 50px 0
        position: relative
        z-index: 1
        button
        .counter-card
            padding: 35px
            background: rgba(68, 68, 68, 0.25)
            border: 1px solid #2F2F2F
            border-radius: 35px
            width: 80%
            margin: auto
            h2
                background: linear-gradient(90deg, #4398FF 0%, #2479DF 58.11%)
                -webkit-background-clip: text
                -webkit-text-fill-color: transparent
                background-clip: text
                text-fill-color: transparent
                color: #ffffff
            p
                text-align: center
                color: #FFFFFF
    .learn-more-container
        width: 100%
        background-image: url(./assests/images/homepage/headerBGimage.png)
        background-position: center
        background-size: cover
        background-repeat: no-repeat
        text-align: center
        padding: 0px 0px 50px
        box-sizing: border-box
        position: relative
        z-index: 1
        .learn-more-video-item
            height: 100%
            display: flex
            max-width: 80rem
            padding: 0px 20px
            margin: auto
            justify-content: space-between
            .learn-more
                width: 50%
                padding: 30px
                box-sizing: border-box
                h3
                    text-align: start
                    font-weight: 500
                    font-size: 2rem
                p
                    width: 90%
                    text-align: start
                    font-size: 1.5rem
            .video-container
                width: 50%
                padding-top: 35px
                .video-item
                    font-size: 1.5rem
                    width: 90%
                    height: 100%
                    min-width: 290px
                    display: flex
                    align-items: center
                    justify-content: center
                    min-height: 400px
                    video
                        width: 100%
                        height: 80%
                        border-radius: 24px
                        object-fit: cover
    .vanture-capital-partner
        position: relative
        z-index: 1
    .faq-container-mobile-view
        display: none
        height: 200px
        width: 100%
        // background: $homepage-background
    .faq-container
        display: flex
        justify-content: space-between
        position: relative
        z-index: 1
        .faq-titles
            width: 40%
            display: grid
            justify-content: end
            background: #16161C
            padding: 50px 50px 50px 0px
            box-sizing: border-box
            border-right: 1px dashed #117DCC
            .faq-titles-heading
                border-bottom: 1px solid #117DCC
                width: 200px
                h1
                    letter-spacing: 20px
            .faq-title-list
                width: 320px
                text-align: start
                ul
                    list-style-type: none
                    li
                        font-size: 1.1rem
                        padding-top: 20px
                        cursor: pointer
                        span
                            color: white
                            font-weight: 500
                        &:hover
                            color: #117DCC
                        &.active-item
                            color: #117DCC
                            span
                                position: relative
                                display: block
                                color: #117DCC
                                &::before
                                    content: ''
                                    margin-top: 10px
                                    position: absolute
                                    left: -27px
                                    height: 68%
                                    width: 2px
                                    background: #117DCC

        .faq-details
            width: 60%
            text-align: start
            background: #27282E
            padding: 50px 0px 50px 50px
            .faq-details-title
                h1
                    color: #117DCC
            .faq-selected-details
                max-width: 50rem
                p
                    font-size: 1.4rem
                    white-space: break-spaces

    .portfolio-container
        padding: 50px 0px
        h1
            text-align: center
            font-size: 1.3rem
            color: #ffffff
            letter-spacing: 1em
            margin: 0px 0px 50px 0px
        .portfolio-list-container
            display: flex
            flex-wrap: wrap
            max-width: 90rem
            margin: auto
            .portfolio-list-item
                width: 33.33%
                display: flex
                justify-content: center
                align-items: center
                padding: 10px
                box-sizing: border-box
                cursor: pointer
                .image-div
                    width: 200px
                    height: 60px
                    text-align: end
                    padding-right: 20px
                    border-right: 1px solid #117DCC
                    img
                        max-width: 100%
                        max-height: 100%
                .return-container
                    text-align: center
                    padding-left: 20px
                    h2
                        margin: 0
                        font-size: 3rem
                        color: #ffffff
                    span
                        display: block
                        color: #ffffff
                        margin-top: -13px

    .partners-container
        text-align: center
        background: #27282E
        padding: 20px 0px
        h2
            margin: auto auto 30px auto
            border-bottom: 1px solid #117DCC
            width: fit-content
            padding-bottom: 15px
        .partners-list-container
            display: flex
            flex-wrap: wrap
            max-width: 90rem
            margin: auto
            .partner-list-item
                width: 33.33%
                padding: 10px 0px
                box-sizing: border-box
                cursor: pointer
                .image-div
                    height: 120px
                    width: 100%
                    img
                        height: 100%
                        width: 100%
                .return-container
                    text-align: center
                    h2
                        margin: 0
                        font-size: 3rem
                    span
                        display: block
                        margin-top: -13px

    .stay-updated-container
        text-align: center
        h2
            letter-spacing: 1em
            border-bottom: 1px solid #117DCC
            color: #ffffff
            width: fit-content
            margin: auto
            padding: 20px
            text-align: center
        p
            margin: 30px
            color: #ffffff
            font-size: 1.4rem
        input
            box-sizing: border-box
            display: block
            margin: auto
            width: 100%
            max-width: 400px
            background: transparent
            border: 1px solid #117DCC
            border-radius: 40px
            height: 36px
            color: #ffffff
            text-align: center
            outline: none
            font-size: 1.3rem
        button
            width: 120px
            border-radius: 40px
            margin: 30px 0px
            cursor: pointer
            color: #ffffff
    .footer-container
        background: #27282E
        height: 300px
        .footer-container-item
            display: flex
            max-width: 90rem
            margin: auto
            height: 100%
            .footer-gen-logo
                width: 400px
                display: grid
                text-align: center
                padding: 25px
                box-sizing: border-box
                .gen-logo-item
                    margin: auto
                    display: grid
                    img
                        width: 100%
                    span
                        margin-top: 30px
                        font-size: 1.3rem
                        color: white
                        font-weight: 500
            .footer-routes-item
                width: 100%
                box-sizing: border-box
                ul
                    display: flex
                    height: 100%
                    flex-direction: column
                    align-items: flex-start
                    align-content: flex-start
                    flex-wrap: wrap
                    list-style: none
                    padding: 40px 0px
                    margin: 0px 50px
                    box-sizing: border-box
                    li
                        width: 25%
                        height: 54px
                        cursor: pointer
                        span
                            color: white
                            font-weight: 500
                            &:hover
                                color: #117DCC

@media screen and (max-width: 1024px)
    .line-height-p-140
        font-size: 10px
        line-height: 140% !important
    .homepage-container
        .welcome-to-container
            .background-logo
                img
                    width: 100%
        .learn-more-container
            .learn-more-video-item
                display: grid
                justify-content: center
                flex-direction: row-reverse
                .learn-more
                    width: 100%
                    p
                        width: 100%
                .video-container
                    width: 100%
                    display: flex
                    justify-content: center
                    padding-top: 0px
        .faq-container
            display: none
        .faq-container-mobile-view
            display: block
        .portfolio-container
            .portfolio-list-container
                .portfolio-list-item
                    width: 50%
                    padding: 20px
                    .image-div
                        width: 150px
                        height: 40px
                        text-align: center
        .partners-container
            .partners-list-container
                .partner-list-item
                    width: 50%
                    .image-div
                        height: 80px
        .stay-updated-container
            h2
                font-size: 1.3rem
                padding: 20px 0px 10px
            p
                font-size: 1.2rem
        .footer-container
            height: fit-content
            .footer-container-item
                display: grid
                .footer-gen-logo
                    width: 100%
                    .gen-logo-item
                        span
                            margin-top: 20px
                .footer-routes-item
                    height: 300px
                    ul
                        li
    .welcome-to-container
        padding: 0 0 25px 0px
        .welcome-content
            .heading-new-1
                font-size: 60.4322px
@media screen and (max-width: 1920px)
    .container-maxwidth
        max-width: 91% !important
@media screen and (max-width: 1600px)
    .heading-new-2
        font-size: 38px
    .heading-new-5
        font-size: 20px
    .heading-new-4
        font-size: 20px
    .heading-new-3
        font-size: 30px
    .fs-47
        font-size: 32px
    .homepage-container
        .welcome-to-container
            .background-logo
                &::before
                    width: 100%
                    height: 100%
                    background-position: center
        .how-we-work-section
            .how-we-work
                .how-we-work-card
                    .bin-scal-number
                        font-size: 70.75px
        .chains-with-section
            h2
                div
                    font-size: 190.021px
                    margin-top: -122px
                    margin-left: 38px
                    margin-bottom: 30px

        .partner-ecosystem-section
            .anxious-slide-item
                padding: 20px
                height: 125px
                img
                    max-height: 45px
    .welcome-to-container
        padding: 0 0 25px 0px
        .welcome-content
            .heading-new-1
                font-size: 40.4322px
@media screen and (max-width: 1400px)
    .padding-top-80
        padding-top: 75px
    .new-black-button
        font-size: 14px
    .new-primary-button
        font-size: 14px
        padding: 12px 25px
    .public-viewing-btn
        font-size: 14px
        padding: 12px 25px
    .heading-new-2
        font-size: 32px
    .heading-new-3
        font-size: 28px
    .heading-new-5
        font-size: 18px
    .heading-new-6
        font-size: 18px
        line-height: 22px
    .heading-new-4
        font-size: 18px
    .fs-47
        font-size: 30px
    .new-anim-btn
        font-size: 18px
        line-height: 22px
        padding: 10px 40px
    .homepage-container
        .counter-card
            padding: 25px
    .homepage-container
        .how-we-work-section
            .how-we-work
                .how-we-work-card
                    .bin-scal-number
                        font-size: 60
                    h6
                        font-size: 22px
                        line-height: 28px
                        margin-bottom: 20px
    .paragraph-new
        font-size: 16px
        line-height: 20px
    .homepage-container
        .chains-with-section
            h2
                div
                    font-size: 160.021px
            .chain-partner
                .partner-img
                    text-align: center
                    padding: 8px 30px
                    img
                        height: 32px
        .past-performance-section
            .past-performance
                .past-performance-card
                    img
                        height: 70px
                        margin-bottom: 20px
        .project-belong-world-section
            .container
                z-index: 1
                position: relative
        .contact-us-section
            .center-line
                height: 250px
        .welcome-to-container
            .background-logo
                img
                    padding: 50px 0
@media screen and (max-width: 1200px)
    .padding-top-80
        padding-top: 60px
    .homepage-container
        .past-performance-section
            .past-performance
                .past-performance-card
                    width: 100%
                    padding: 30px 20px
                    img
                        height: 45px
    .homepage-container
        .welcome-to-container
            .background-logo
                &::before
                    background-position: center
                    width: 100%
                    height: 100%
                img
                    width: 50%
                    padding: 40px 0px 40px 0
    .heading-new-2
        font-size: 28px
    .heading-new-3
        font-size: 24px
    .homepage-container
        .counter-card
            border-radius: 20px
            .counter-img
                margin-top: -50px
    .paragraph-new-medium
        font-size: 14px
    .homepage-container
        .partner-ecosystem-section
            .anxious-slide-item
                height: 145px
                img
                    max-height: 45px
    .homepage-container
        .contact-us-section
            padding: 30px 0
    .homepage-container
        .partner-ecosystem-section
            .partner-ecosystem
                padding: 30px 0
    .heading-new-5
        font-size: 16px
    .heading-new-4
        font-size: 16px
    .paragraph-new
        font-size: 14px
        line-height: 18px
    .fs-47
        font-size: 26px
    .fs-82
        font-size: 43px
        line-height: 55px
    .new-black-button
        font-size: 12px
    .homepage-container
        .powerd-by-section
            .powerd-by-text
                button
                    font-size: 14px
                    line-height: 20px
                    img
                        max-height: 35px
    .homepage-container
        .chains-with-section
            h2
                div
                    font-size: 100px
                    margin-top: -70px
                    margin-left: 12px
                    margin-bottom: 42px
    .homepage-container
        .belong-world-counter
            .counter-card
                padding: 20px
                border-radius: 20px
    .new-primary-button
        font-size: 14px
        padding: 12px 20px
    .public-viewing-btn
        border-radius: 10px
        font-size: 14px
        line-height: 80%
        padding: 12px 20px
    .new-white-button
        border-radius: 10px
        font-size: 14px
        line-height: 80%
        padding: 10px 20px

    .new-color-button
        font-size: 10px
        padding: 12px 15px
    .new-anim-btn
        font-size: 18px
        line-height: 22px
@media screen and (max-width: 992px)
    .main-tab-panel-container
        background: transparent
    .counter-card
        margin-bottom: 50px
    .welcome-to-container
        .row.mb-5
            // .heading-new-2
            //     br
            //         display: none
            .live-arbitrum
                margin: 12px 0 12px 0
            .logo-flex
                img
                    max-height: 35px
    .homepage-container
        .powerd-by-section
            .powerd-by-text
                button
                    font-size: 14px
                    line-height: 19px
                    margin: 13px 0 17px 0
    .homepage-container
        .chains-with-section
            .chain-partner
                .partner-img
                    padding: 5px 20px
    .homepage-container
        .past-performance-section
            .past-performance
                .past-performance-card
                    img
                        height: 60px
                        margin-bottom: 15px
    .homepage-container
        .belong-world-counter
            .counter-card
                padding: 20px
                background: rgba(68, 68, 68, 0.25)
                border: 1px solid #2F2F2F
                border-radius: 20px
                width: 100%
    .new-anim-btn
        font-size: 14px
        line-height: 18px
        padding: 8px 30px
    .fs-47
        font-size: 20px
    .contact-us-section
        // .col-lg-1
        //     display: none
        .heading-new-2
            br
                display: none
    .homepage-container
        .contact-us-section
            h2
                margin-bottom: 20px
    .homepage-container
        .how-we-work-section
            .how-we-work
                .how-we-work-card
                    padding-bottom: 20px
    .homepage-container
        .powerd-by-section
            padding-top: 0
            .powerd-by-text
                h2
                    br
                        display: none
        .project-belong-world-section
            img
                width: 50%
    .paragraph-new-small
        font-size: 12px
@media screen and (max-width: 767px)
    .padding-top-80
        padding-top: 40px
    .new-anim-btn
        font-size: 10.5623px
        line-height: 13px
        padding: 7px 15px
    .container-maxwidth
        max-width: 100% !important
    .counter-card
        margin-bottom: 30px
    .homepage-container
        .welcome-to-container
            padding-bottom: 0
            &::after
                display: none
            .background-logo
                position: absolute
                top: -35px
                right: -20px
            .welcome-content
                .live-arbitrum
                    p
                        font-size: 6.17092px
                        line-height: 80%
                        margin-right: 0
            .row.mb-5
                .logo-flex
                    img
                        max-height: 19px
        .powerd-by-section
            padding-bottom: 0
            .powerd-by-text
                text-align: center
                i
                    font-size: 25px
                button
                    margin: 15px auto
                    font-size: 9px
                    padding: 4px 15px
                    img
                        max-height: 23px
        .powerd-by-section
            padding-top: 0
            .powerd-by-text
        .project-belong-world-section
            padding-bottom: 0
            h2
                font-size: 19.4442px
            img
                width: 80%
        .chains-with-section
            padding-bottom: 0
            .rocket-icon
                height: 25px
                margin-bottom: 12px
            h2
                div
                    font-size: 134px
                    margin-top: -50px
                    margin-left: 20px
                    margin-bottom: 15px
                    -webkit-text-stroke-width: 2px
            .chain-partner
                gap: 15px
                .partner-img
                    img
                        max-height: 28px
        .past-performance-section
            .past-performance
                padding-bottom: 0
                .past-performance-card
                    padding: 20px
                    margin-bottom: 20px
                    border-radius: 14.9863px
                    h2
                        font-size: 23.9781px
                    img
                        height: 30px
                        margin-bottom: 10px
        .partner-ecosystem-section
            .anxious-slide-item
                padding: 15px
                height: auto
                border-radius: 9.70704px
                img
                    height: 25px
            .paragraph-new-medium
                font-size: 6px
            .partner-ecosystem
                .slick-arrow.slick-prev
                    left: 42%
                    top: 80%
                    height: 22px
                    width: 22px
                .slick-arrow.slick-next
                    left: 51%
                    top: 80%
                    height: 22px
                    width: 22px
                .slick-slider
                    padding-bottom: 50px
        .contact-us-section
            .center-line
                height: 187px
                width: 2px

    .actions-buttons
        button
            font-size: 8px
    .heading-new-1
        font-size: 35px
    .heading-new-2
        font-size: 22px
    .fs-47
        font-size: 13px
    .fs-82
        font-size: 20.2632px
        line-height: 15px
    .heading-new-3
        font-size: 18px
    .heading-new-4
        font-size: 16px
    .heading-new-5
        font-size: 14px
    .heading-new-6
        font-size: 16px
    .paragraph-new
        font-size: 12px
    .paragraph-new-medium
        font-size: 12px
    .paragraph-new-small
        font-size: 12px
    .sup-new-small
        font-size: 12px
    .new-primary-button
        padding: 6px 10px
        font-size: 14px
    .public-viewing-btn
        padding: 6px 10px
        border-radius: 10px
        font-size: 10px
    .new-white-button
        padding: 6px 10px !important
        border-radius: 3px !important
        font-size: 10px !important
    .fs-12
        font-size: 12px
    .new-color-button
        font-size: 12px
        padding: 6px 15px
    .new-black-button
        font-size: 10px
