.details-card
    background: rgba(21, 62, 112, 0.5)
    border-radius: 18px
    margin-top: 30px
    overflow: hidden
    position: relative
    .event-content-container
        padding: 20px 20px 10px
    .card-image-section
        margin-bottom: 20px
        min-height: 100px
        .card-image
            // position: absolute
            transform: scale(1.1)
            top: 0
            left: 0
            width: 100%
            object-fit: contain
    .heading-new-4
        margin-top: 20px
    .guests
        margin-top: 20px
    .bottom-link
        margin-top: 20px
        a
            text-decoration: none
            color: white
            .ri-link
                height: 24px
                width: 24px
                border-radius: 50%
                padding: 2px
                color: black
                background: white

@media screen and (max-width: 1600px)
    .details-card
        .heading-new-4
            margin-top: 20px
        .guests
            margin-top: 20px
        .bottom-link
            margin-top: 20px

@media screen and (max-width: 767px)
    .details-card
        padding: 20px 15px
        border-radius: 12.7403px
        margin-top: 20px
        .event-content-container
            padding: 10px 0px 0px !important
        .heading-new-4
            margin-top: 15px
        .guests
            margin-top: 15px
        .bottom-link
            margin-top: 15px
