.description-modal {
  max-width: 660px !important;
  color: white;
  .modal-content {
    border: 2px solid #4398FF;
  }
  .description-modal-container {
    min-height: 300px;
    margin-top: 1rem;
    position: relative;

    .deshboard-social {
      min-width: 40%;
      margin: auto;
      .social-link-table {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        padding-left: 0;
        margin-bottom: 0;
        li {
          list-style: none;
          a {
            width: 24px;
            height: 24px;
            color: black;
            background: #4398FF;
            display: flex;
            align-items: center;
            justify-content: center;
            text-decoration: none;
            border-radius: 50%;
          }

        }

      }

    }

  }
}