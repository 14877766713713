@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap')

*
  font-family: "Oswald", sans-serif
  // font-weight: 300

#rich-text-editor-id *
  font-family: inherit
  font-weight: inherit

a
  text-decoration: none

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button
  -webkit-appearance: none
  margin: 0
.baselayout__content
  overflow: hidden
.form-item__err
  color: red
  font-size: 0.8rem
  margin-top: 0.25rem

.jLKupL[data-reach-dialog-overlay]
  display: -webkit-inline-box !important

@media (min-width: 1400px)
  .container
    max-width: 1200px !important

@media (min-width: 1600px)
  .container
    max-width: 1400px !important

@media screen and (min-width: 576px)
  .custome-container
    max-width: 540px
    padding-left: 15px
    padding-right: 15px

@media screen and (min-width: 768px)
  .custome-container
    max-width: 720px

@media screen and (min-width: 992px)
  .custome-container
    max-width: 960px

@media screen and (min-width: 1200px)
  .custome-container
    max-width: 1140px

@media screen and (min-width: 1400px)
  .custome-container
    max-width: 1320px

.custome-container
  width: 100%
  margin: auto
