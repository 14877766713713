.study-program-container
    .top-header-container
        display: flex
        justify-content: space-between
        margin-bottom: 20px
        .mark-viewed-item
            display: flex
            align-items: center
            background: #4b4b4b
            color: white
            padding: 0px 20px 0px 8px
            border-radius: 26px
            cursor: pointer
            span
                font-weight: 500
                cursor: pointer

.study-programing-page
    .courses-section
        .external-content-heading
            display: flex
            align-items: center
            i
                font-size: 26px
                margin-top: -16px
                margin-right: 8px
        &:first-child
            margin-top: 0 !important
            .update-heading
                background: linear-gradient(180deg, #4398FF 0%, #2479DF 100%)
                -webkit-background-clip: text
                -webkit-text-fill-color: transparent
                background-clip: text
                text-fill-color: transparent
            .media-slider
                padding: 30px
                background: rgba(121, 121, 121, 0.25)
                border-radius: 49px
                margin-top: 20px !important
        .external-icon
            font-size: 60px
            color: #fff
        .media-slider
            .slide-content
                text-align: center
                .new-color-button-div
                    margin-top: -25px
                    text-align: center
                    position: relative
                    .new-color-button
                        border-radius: 50px
                        width: 140px
                .paragraph-new
                    width: 70%
                    margin: auto
            .slick-arrow
                display: block
                bottom: -30px
                top: auto
                &.slick-prev
                    left: 48%
                    z-index: 1
                    &::before
                        color: #4398FF
                        font-size: 25px
                &.slick-next
                    right: 48%
                    z-index: 1
                    &::before
                        color: #4398FF
                        font-size: 25px
.video-details
    .card-player
        video
            border-radius: 24.4487px
    iframe
        width: 100%
        height: 660px
        border-radius: 34px

.media-slider-heading
    &::first-letter
        text-transform: uppercase
.managing-finances-color
    color: #757575
@media screen and (max-width: 1600px)
    .study-programing-page
        .courses-section
            .media-slider
                .slide-content
                    .paragraph-new
                        width: 80%
@media screen and (max-width: 1024px)
    .study-programing-page
        .courses-section
            margin-top: 40px
            .external-icon
                font-size: 35px
            &:first-child
                .media-slider
                    border-radius: 30px

@media screen and (max-width: 767px)
    .video-details iframe
        height: 300px
    .study-programing-page
        .courses-section
            .external-icon
                font-size: 25px
                display: flex
                justify-content: center
            &:first-child
                .media-slider
                    border-radius: 15px
                    text-align: center
                    padding: 10px
                    .slick-slide
                        .slide-content
                            text-align: center
                            .content
                                video
                                    border-radius: 13.6875px
            .heading-new-4
                text-align: center
            .media-slider
                .slide-content
                    .paragraph-new
                        width: 95%
    .managing-finances-color
        font-family: 'Space Grotesk'
        font-style: normal
        font-weight: 700 !important
        font-size: 20.7605px
        line-height: 104.5%
        text-align: center
        color: #FFFFFF
        margin-bottom: 10px
        text-align: center !important
        align-items: center
