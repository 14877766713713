@import "../../../../../variables.sass"

.lead-section
    .connection-section
        .all-mentor-section
            img
                // box-shadow: 0px 2px 19px 3px #2378DE
.network-section
    .request
        border-bottom: 1px solid #777777
    .network-catogory
        background: $card-background
        // border: 1px solid #2F2F2F
        border-radius: 18px
        padding: 25px 40px
        .network-category-container
            display: flex
            overflow-x: scroll
            .network-category-item
                background: $card-item-background
                border-radius: 10px
                padding: 4px 10px
                width: max-content
                margin-right: 10px
                .form-check-input
                    background-color: transparent
                    border: 1px solid #fff
                    border-radius: 50%
                    cursor: pointer
                    &:checked[type=checkbox]
                        background-image: none
                        background: #fff
                    &:focus
                        box-shadow: none
                .form-check-label
                    cursor: pointer
    .network-catogory-data
        position: relative
        min-height: 300px
        .mentor-loader-container
            // position: absolute
            // top: 0
            height: 300px
            width: 100%
            display: flex
            justify-content: center
            align-items: center
        // &.scrolled
        //     height: 800px
        //     overflow-y: scroll
        //     overflow-x: hidden
@media screen and (max-width: 1200px)
    .lead-section
    .network-section
        .network-catogory
            padding: 25px
            .network-category-item
                .form-check-label
                    font-size: 13px

@media screen and (max-width: 767px)
    .network-section
        .network-catogory
            padding: 10px
            .network-category-item
                padding: 7px 10px
                .form-check
                    display: flex
                    align-items: center
                    margin-bottom: 0
                    input
                        margin-top: 0.12em
                .form-check-label
                    // font-size: 10px
                    // line-height: 12px
