@import "../../../../../variables.sass"

.launch-network-section
    .lead-section
        .currently-filter
            background: $card-background
            // border: 1px solid #2F2F2F
            border-radius: 18px
            padding: 2%
            .currently-tab
                margin-top: 16px
                display: flex
                justify-content: center
                align-items: center
                gap: 14px
                flex-wrap: wrap
                span
                    background: $card-item-background
                    border-radius: 10px
                    font-size: 14px
                    line-height: 104.5%
                    text-align: center
                    color: #FFFFFF
                    height: 40px
                    width: max-content
                    padding: 10px
                    box-sizing: border-box
                    display: block
                    cursor: pointer
                    &:hover
                        background: $card-item-hover-background
                    &.selected
                        background: $text-primary-color
                        cursor: default
                    &.not-selected
        .connection-section
            min-height: 80px
            position: relative
            padding-right: 10px
            .all-mentor-section
                background: #161616
                padding: 7% 10px
                border-radius: 16px
                .limit-paragraph
                    display: -webkit-box
                    -webkit-line-clamp: 2
                    -webkit-box-orient: vertical
                    overflow: hidden
                    text-overflow: ellipsis
                    height: 50px
                .partner-logo-img
                    object-fit: cover
                    height: 130px !important
                    width: 130px !important
                    border: 1px solid #FFFFFF !important
                    border-radius: 50% !important
            .parent-full-height
                position: absolute
                height: 100%
                width: 100%
                display: flex
                justify-content: center
                align-items: center
        .launch-filter
            .connection-section
                &.scrolled
                    max-height: 850px
                    overflow-y: scroll
                    overflow-x: hidden
@media screen and (max-width: 1600px)
    .launch-network-section
        .lead-section
            .connection-section
                p
                    display: -webkit-box
                    -webkit-line-clamp: 1
                    -webkit-box-orient: vertical
                    overflow: hidden
                    text-overflow: ellipsis
@media screen and (max-width: 1200px)
    .launch-network-section
        .lead-section
            .currently-filter
                .currently-tab
                    gap: 10px
            .connection-section
                .all-mentor-section
                    .paragraph-new-medium
                        font-size: 12px
@media screen and (max-width: 767px)
    .launch-network-section
        .lead-section
            .connection-section
                .all-mentor-section
                    .partner-logo-img
                        object-fit: cover
                        height: 100px !important
                        width: 100px !important
            .currently-filter
                padding: 10px 0
                .currently-tab
                    .css-1gsv261
                        .css-heg063-MuiTabs-flexContainer
                            display: grid
                            grid-template-columns: auto auto
                            gap: 10px
                            .css-1h9z7r5-MuiButtonBase-root-MuiTab-root
                                font-size: 9px
                                padding: 10px 15px
