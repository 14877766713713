.thank-section
    position: relative
    .thank-text-section
        background: rgba(68, 68, 68, 0.25)
        border: 2px solid #4398FF
        border-radius: 25.93px
        height: 800px
        width: 100%
        text-align: center
        display: flex
        justify-content: center
        align-items: center
        h3
            // font-family: 'Space Grotesk'
            // font-style: normal
            // font-weight: 400
            // font-size: 46.7668px
            // line-height: 60px
            // text-align: center
            // color: #FFFFFF
            width: 75%
            margin: auto
        p
            // font-family: 'Space Grotesk'
            // font-style: normal
            // font-weight: 300
            // font-size: 22.3919px
            // line-height: 29px
            // text-align: center
            // color: #FFFFFF

@media screen and (max-width: 1400px)
    .thank-section
        .thank-text-section
            height: 500px
            img
                max-width: 60px

@media screen and (max-width: 1200px)
    .thank-section
        .thank-text-section
            height: 400px
            img
                max-width: 60px

@media screen and (max-width: 767px)
    .thank-section
        .thank-text-section
            height: 300px
            img
                max-width: 50px
            .fs-47
                font-size: 18px
                line-height: 23px
