@import "../../../../../../../variables.sass"

.symbioteScale-section
    .symbioteScale-box-section
        background: $card-background
        // border: 1px solid #2F2F2F
        border-radius: 25.93px
        padding: 50px
        .rc-slider-disabled
            background: transparent
        .rc-slider-step
            .rc-slider-dot
                display: none !important
        .symbioteScale-slider
            padding: 15px
            border: 0.43368px solid #FFFFFF
            border-radius: 13.8778px
            width: 100%
            margin-top: 40px
            .rc-slider-mark-text
                width: min-content
                top: -110px
                font-size: 16px
                line-height: 20px
                text-align: center
                color: #ffffff
        .icon-content-text
            margin-top: -20px
        .launch-text
            background: linear-gradient(180deg, #4398FF 0%, #2479DF 100%)
            -webkit-background-clip: text
            -webkit-text-fill-color: transparent
            background-clip: text
            text-fill-color: transparent
            width: max-content
        .crucial-text
            width: 70%
            margin: auto
    &.range-slider
        .symbioteScale-slider
            .rc-slider-mark-text
                font-weight: 300
                &.rc-slider-mark-text-active
                    font-weight: 600
@media screen and (max-width: 1600px)
    .symbioteScale-section
        .symbioteScale-box-section
            padding: 40px
            .symbioteScale-slider
                .rc-slider-mark-text
                    font-size: 14px
                    line-height: 15px
@media screen and (max-width: 1400px)
    .symbioteScale-section
        .symbioteScale-box-section
            padding: 20px
            .launch-heading
                .launch-text
                    font-size: 14px
                    line-height: 16px
            .symbioteScale-slider
                .rc-slider-mark-text
                    top: -90px
                    font-size: 12px
                    line-height: 15px

@media screen and (max-width: 1200px)
    .symbioteScale-section
        .symbioteScale-box-section
            .rc-slider-mark-text
                font-size: 14px
                line-height: 17px

@media screen and (max-width: 992px)
    .symbioteScale-section
        .symbioteScale-box-section
            .symbioteScale-slider
                .rc-slider-mark-text
                    font-size: 10px
                    line-height: 12px
            .launch-heading
                img
                    max-width: 45%
                .launch-text
                    font-size: 12px
                    line-height: 14px
    .symbioteScale-section
        .symbioteScale-box-section
            padding: 20px
            .symbioteScale-slider
                .rc-slider-mark-text
                    top: -85px
                span
                    font-size: 12px
                    line-height: 14px
            .crucial-text
                width: 90%

@media screen and (max-width: 767px)
    .symbioteScale-section
        .symbioteScale-box-section
            .launch-heading
                display: flex
                align-items: center
                justify-content: center
                gap: 10px
                margin-left: 100px
                margin-top: 10px
                margin-bottom: 20px
                img
                    max-width: 30px
                .launch-text
                    width: auto
    .symbioteScale-section
        .symbioteScale-box-section
            border-radius: 16.93px
            .symbioteScale-slider
                display: block !important
                transform: rotate(90deg)
                min-width: 444px
                margin-top: 0
                .launch-text
                    width: auto
                .launch-heading
                    img
                        max-width: 12%
                    p
                        margin: auto
                        margin-top: 10px !important
                        width: auto
                .rc-slider-mark-text
                    transform: rotate(270deg) !important
                    text-align: left
                    width: 80px
                .rc-slider-mark
                    left: -39px !important
                    top: -2px !important
            .symbioteScale-slider-main
                display: flex
                align-items: center
                justify-content: center
                margin-right: 70px
                height: 444px
            .icon-content-text
                margin-top: 14px
                display: flex
                align-items: center
                justify-content: center
                img
                    margin-right: 10px
