
.vc-card
    background: rgba(68, 68, 68, 0.25)
    border: 0.912272px solid #2F2F2F
    border-radius: 18.2454px
    margin: 20px 0
    position: relative
    .top-button
        position: absolute
        top: -18px
        left: 18px
        display: flex
        align-items: center
        justify-content: space-between
        width: 90%
        // button
        //     border-radius: 50px
        //     min-width: 100px
        //     min-height: 35px
        //     strong
        //         font-weight: 600 !important
        //         font-family: 'Space Grotesk'
        //         font-style: normal
        //         font-weight: 300
        //         font-size: 12.6457px
        //         line-height: 16px
    .banner-image
        margin-top: 24px
        margin-bottom: 15px
        img
            background: #2D2D2D
            outline: none
            border: none
            height: 150px
            width: 100%
            object-fit: cover
    .text-content
        padding: 0 40px
        padding-bottom: 20px
        .card-some-btn
            button
                font-size: 18px
                line-height: 110%
                padding: 5px 20px
        .left-part
            .user-image
                display: flex
                align-items: center
                gap: 20px
                img
                    height: 85px
                    width: 85px
                    min-height: 85px
                    min-width: 85px
                    border-radius: 50%
                    object-fit: cover
                h5
                    &::first-letter
                        text-transform: uppercase
            .card-social
                margin-top: 20px
                ul
                    display: flex
                    align-items: center
                    gap: 20px
                    padding-left: 0
                    margin: 0
                    li
                        list-style: none
                        a
                            min-height: 30px
                            min-width: 30px
                            height: 30px
                            width: 30px
                            border-radius: 50%
                            background: #4398FF
                            display: flex
                            align-items: center
                            justify-content: center
                            color: black
                            text-decoration: none
        .right-part
            .cercle-slider
                gap: 15px
            p
                line-height: 20px
                height: 80px
                overflow: hidden
        .project-card-actions
            display: flex
            gap: 10px
            justify-content: center
            .card-button
                font-family: 'Space Grotesk'
                font-style: normal
                font-weight: 700
                font-size: 14px
                line-height: 16px
                text-align: center
                color: #FFFFFF
                border-radius: 25.0128px
                border: 2px solid #4398FF
                background: transparent
                padding: 6px 20px
                &.interested
                    background: #4398FF

.card-percentage-slider
    svg
        height: 56px
        width: 56px
.CircularProgressbar
    .CircularProgressbar-text
        font-family: 'Space Grotesk'
        font-style: normal
        font-weight: 700
        font-size: 24.1816px
        line-height: 31px
        text-align: center
        color: #FFFFFF

@media screen and (max-width: 1600px)
    .vc-card
        .text-content
            padding: 0 30px 30px 30px
            .left-part
                .user-image
                    img
                        height: 70px
                        width: 70px
                        min-height: 70px
                        min-width: 70px
            .card-button
                font-size: 16.7003px
                line-height: 22px
            .card-some-btn
                button
                    font-size: 16px
                    padding: 5px 15px
            .right-part
                .card-percentage-slider
                    svg
                        height: 45px
                        width: 45px
                    .symbiotescore-text
                        h3
                            font-size: 14px

@media screen and (max-width: 1400px)
    .vc-card
        .text-content
            .left-part
                .user-image
                    gap: 15px
                    img
                        height: 60px
                        width: 60px
                        min-height: 60px
                        min-width: 60px
                .card-social
                    ul
                        gap: 15px
                        li
                            a
                                min-height: 25px
                                min-width: 25px
                                height: 25px
                                width: 25px
            .card-some-btn
                button
                    font-size: 14px
                    padding: 5px 15px
            .right-part
                .cercle-slider
                    gap: 10px
                .symbiotescore-text
                    h3
                        word-break: break-word

@media screen and (max-width: 992px)
    .vc-card
        .banner-image
            img
                height: 120px
        .top-button
            button
                min-width: 60px
                min-height: 27px
                padding: 5px 10px
                font-size: 12px
        .text-content
            padding: 0 15px 15px 15px
            .card-button
                font-size: 12px
                line-height: 14px
                border-width: 1.14px
            .left-part
                .user-image
                    gap: 10px
                    img
                        height: 50px
                        width: 50px
                        min-height: 50px
                        min-width: 50px
                .card-social
                    ul
                        gap: 10px
                        li
                            a
                                min-height: 25px
                                min-width: 25px
                                height: 25px
                                width: 25px
            .card-some-btn
                button
                    font-size: 12px
            .right-part
                .card-percentage-slider
                    svg
                        height: 35px
                        width: 35px
                .cercle-slider
                    gap: 10px
@media screen and (max-width: 767px)
    .project-card-actions
        flex-direction: column
        margin-top: 10px
        .card-button
            margin: auto
            font-size: 14px !important
            width: fit-content
    .vc-card
        border-radius: 10.8605px
        width: 95%
        margin: auto
        margin-top: 18px
        margin-bottom: 18px
        .top-button
            margin-top: -12px
            button
                min-height: 22px
        .banner-image
            margin: 10px 0
            img
                height: 90px
        .text-content
            padding: 0 10px 10px 10px
            .left-part
                .card-social
                    margin-top: 12px
                    ul
                        li
                            a
                                min-height: 17px
                                min-width: 17px
                                height: 17px
                                width: 17px
                                i
                                    font-size: 12px
            .right-part
                .card-percentage-slider
                    svg
                        height: 25px
                        width: 25px
                .heading-new-6
                    margin-top: 10px !important
                    font-size: 14px
                    line-height: 16px
                    font-weight: 600
                p
                    font-size: 12px
                    line-height: 14px
                    height: 86px !important
            .card-some-btn
                button
                    font-size: 10px
