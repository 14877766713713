.connection-request-model
    min-width: 660px
    .modal-header
        padding: 0
        border: none
        .btn-close
            display: none
        .close-btn
            position: absolute
            right: 17px
            top: 10px
            z-index: 1
            cursor: pointer
            svg
                width: 25px
                height: 25px
                path
                    fill: #4398FF
    .modal-content
        background: #0D0D0D !important
        border-radius: 20px !important
        overflow: hidden
        .modal-body
            .connection-request-details
                max-width: 80%
                margin: auto
                padding: 10px
                box-sizing: border-box
                .model-success
                    height: 350px
                    display: flex
                    flex-direction: column
                    align-items: center
                    justify-content: center
                    .sucess-text
                        font-family: 'Space Grotesk'
                        font-style: normal
                        font-weight: 300
                        font-size: 62.4px
                        line-height: 104.5%
                        color: #2BEB33
                .partner-details
                    img
                        height: 55px
                        width: 55px
                        min-width: 55px
                        min-height: 55px
                        border-radius: 50%
                        object-fit: cover
                .connection-request-textarea
                    input, textarea
                        background: rgba(68, 68, 68, 0.25)
                        border: 1px solid #2F2F2F
                        border-radius: 22px
                        font-family: 'Space Grotesk'
                        font-style: normal
                        font-weight: 300
                        font-size: 18px
                        line-height: 23px
                        color: #FFFFFF
                        width: 85%
                        margin: auto
                        &::placeholder
                            color: grey !important
                button
                    padding: 8px 55px

@media screen and (max-width: 1200px)
    .connection-request-model
        .modal-content
            .modal-body
                .connection-request-details
                    .model-success
                        .sucess-text
                            font-size: 46px
@media screen and (max-width: 767px)
    .connection-request-model
        min-width: 90%
        .modal-content
            width: 90%
            margin: auto
            .modal-body
                padding: 0
                .connection-request-details
                    max-width: 100%
                    .model-success
                        height: 200px
                        h5
                            font-size: 12px
                        .sucess-text
                            font-size: 26px
                    .heading-new-3
                        font-size: 14px
                        line-height: 120%
                    .connection-request-textarea
                        input,textarea
                            border-radius: 11.2973px
                            font-size: 10px
                            line-height: 12px
                            width: 100%
        .modal-header
            .close-btn
                display: flex
                align-items: center
                justify-content: center
                right: 10px
                top: 10px
                svg
                    height: 18px
                    width: 18px
